.header-search-form
	visibility: hidden
	@apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 backdrop-blur-md z-[1000] flex justify-center items-center transition-all duration-300 opacity-0  pointer-events-none
	.close
		@apply right-0
	&.active
		@apply opacity-100 visible pointer-events-auto
	.wrap-form-search-product
		max-width: r(800px)
		margin: 0 auto
		@apply w-full
		.productsearchbox
			@apply w-full flex relative flex-wrap
			[class*="Module"]
				width: 100% !important
			.searchbox
				@apply w-full flex
			input
				@apply flex-1 h-[calc(50/1920*100rem)] bg-white text-base px-6 outline-0 border-0 #{!important}
			button
				@apply w-9 flex items-center justify-center bg-white text-[0px] outline-0 border-0 border-l border-stroke-E0
				&::before
					content: '\f002'
					font-family: "Font Awesome 6 Pro"
					color: #222222
					@apply text-base
