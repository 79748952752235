.post-detail
	font-family: League Spartan
	.post-title
		color: #08518F
		font-size: 36px
		font-style: normal
		font-weight: 500
		line-height: calc( 42px / 36px )
	.full-content
		p
			@apply global-text-20 text-black
			&:first-child
				@apply font-medium
			&+p
				@apply mt-5
			img
				@apply w-full object-cover
		a
			@apply text-red-500
		iframe
			@apply inline
	.post-left
		@apply relative
		.post-date
			@apply flex my-6 text-body-text-99 gap-2 relative
			em
				@apply leading-1.25
			p
				@apply leading-1.5
			&::after
				content: ''
				width: calc(100% - rr(120px))
				@apply absolute top-1/2 right-0
				@apply h-[calc(1/1920*100rem)] bg-blue-500 -translate-y-1/2
	.post-right
		h2
			@apply global-text-24 text-red-500 mb-5

	.showListPostNews
		&+.showListPostNews
			@apply mt-4
		&:hover
			.post-list-title
				&::before
					@apply bg-red-500
			h3
				@apply text-red-500
		.post-image, .post-list-title
			@apply w-full
		.post-image
			flex: 0 0 calc(138/416*100%)
			max-width: calc(138/416*100%)
			height: rr(92px)
			&>a
				@apply block w-full h-full
			img
				@apply w-full object-cover h-full
		.post-list-title
			flex: 0 0 calc(261/416*100%)
			max-width: calc(261/416*100%)
			@apply relative pt-2
			&::before
				content: ''
				@apply absolute top-0 left-0 w-full h-[calc(2/1920*100rem)] bg-transparent
				@apply transition-all duration-300
		.post-date
			@apply flex text-body-text-99 gap-2
			em
				@apply leading-1.25
			p
				@apply leading-1.5
		h3
			@apply global-text-20 text-black font-normal line-clamp-2

.social-share
	@apply mt-8
	a
		@apply inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-50
		@apply text-2xl text-blue-500
	@screen xl
		left: rr(-80px)
		@apply absolute top-0 mt-0

.news-detail-page
	.show-news
		@apply block
