.home-1
	@apply relative z-10 
	&-swiper
		@apply -mx-2.5
		.swiper
			@apply px-2.5
		.swiper-slide
			@apply h-auto pt-12
		.item
			@apply h-full
			.box-img
				background: none !important
				height: rr(152px)
				padding: 0
				img
					@apply w-full h-full rounded-none
		@media(max-width: 1279.98px)
			@apply pb-5
	.item-product-cate
		&:hover, .active
			box-shadow: 3px 3px 8px -2px rgb(0, 0, 0)
			@apply -translate-y-5