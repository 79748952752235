.about-4
	@apply relative
	h2
		@media(max-width: 767.98px)
			@apply mb-10
	.wrapper
		@apply py-15 relative
		&::before
			background: linear-gradient(180deg, rgba(232, 248, 255, 0.00) 0%, #E8F8FF 50%, rgba(238, 221, 232, 0.00) 100%, rgba(232, 248, 255, 0.00) 100%)
			content: ''
			@apply absolute inset-0
		@screen lg
			transform: translateY(rr(-15px))
			@apply py-[calc(120/1920*100rem)]
	.tabslet-tab
		@apply relative z-20
		li
			@apply flex items-center md:items-start
			&+li
				@apply mt-10 lg:mt-20
		a, .content
			@apply w-full
		a
			flex: 0 0 25%
			max-width: 25%
			&:hover
				.img-src
					img
						@apply border-red-500
				&+.content
					p
						@apply text-red-500
			.img-src
				img
					@apply border-[calc(4/1920*100rem)] border-white rounded-full
			@screen md
				flex: 0 0 calc(160/416*100%)
				max-width: calc(160/416*100%)
		.content
			flex: 0 0 75%
			max-width: 75%
			.icon
				@apply h-[calc(48/1920*100rem)] mb-3
				img
					@apply h-full object-contain
			p
				@apply text-blue-500 text-28 font-medium leading-1.25 relative uppercase
				&::after
					content: ''
					top: calc(100% + rr(12px))
					@apply absolute h-[calc(1/1920*100rem)] w-10 bg-red-500 left-0
			@screen md
				flex: 0 0 calc(256/416*100%)
				max-width: calc(256/416*100%)
		&.tab-left
			@apply mt-20
		@screen md
			&.tab-left
				@apply mt-0
				a
					@apply order-2
				.content
					@apply order-1 text-right
					p
						&::after
							left: unset
							@apply right-0
			&.tab-right
				a
					@apply order-1
				.content
					@apply order-2 text-left
		@screen lg
			&.tab-left
				li
					@apply translate-x-[15%]
			&.tab-right
				li
					@apply -translate-x-[15%]
	.col
		&.col-center
			@apply flex-center
	.tabslet-content
		h3
			@apply global-text-40 text-red-500 font-medium mb-3
		.desc
			@apply global-text-24 text-[#151515]
	.item
		@apply relative pt-[70%] md:pt-full w-full
		.bg-img
			@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
			@apply w-full sm:w-[calc(420/1920*100rem)] md:w-[calc(584/1920*100rem)] h-[calc(420/1920*100rem)] md:h-[calc(584/1920*100rem)]
			&>img
				mix-blend-mode: overlay
			&::before
				content: ''
				background: linear-gradient(180deg, rgba(228, 242, 255, 0.20) 0%, #DBF6FF 25%, #C8EBFF 50%, rgba(228, 242, 255, 0.20) 82.29%, rgba(228, 242, 255, 0.00) 100%)
				filter: blur(20px)
				@apply absolute w-full sm:w-[calc(672/1920*100rem)] h-[calc(672/1920*100rem)] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
			@media(max-width: 767.98px)
				&::before
					@apply h-[calc(520/1920*100rem)]
		.content-innert
			box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.10)
			@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex-center
			@apply w-[calc(260/1920*100rem)] md:w-[calc(344/1920*100rem)] h-[calc(260/1920*100rem)] md:h-[calc(344/1920*100rem)] bg-white rounded-full
		.img-src
			@apply h-20 md:h-[calc(97/1920*100rem)]
			img
				@apply h-full object-contain
	.tabslet-wrapper
		@apply text-center mt-10 lg:mt-0
		@screen lg
			transform: translateY(rr(25px))
	.about-4-top
		@media(max-width: 1023.98px)
			.col
				&:nth-child(1)
					@apply order-2
				&:nth-child(2)
					@apply order-1 mb-10
				&:nth-child(3)
					@apply order-3
		@media(max-width: 767.98px)
			.col
				&:nth-child(3)
					@apply mt-10
	.mobile-tabslet-group
		@apply relative
		.tabslet-content
			@apply mt-5
		& + .mobile-tabslet-group
			@apply mt-10
	// @media(max-width: 575.98px)
		// .about-4-top
		// 	> .row
		// 		@apply flex-nowrap m-0 overflow-x-auto justify-start
		// .tabslet-tab.tab-left
		// 	@apply flex-nowrap
		// 	li
		// 		@apply w-max
		// 		a
		// 			@apply whitespace-nowrap
