.sustainability-5
	&.section-medium
		@screen xl
			@apply pt-8
	ul
		@apply flex items-center sm:justify-center gap-5 mb-5
		li
			&.active
				a
					@apply bg-red-500 text-white border-red-500
		a
			@apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20
			@apply inline-flex items-center justify-center py-2 px-2.5 whitespace-nowrap
			@apply transition-all duration-300
			&:hover
				@apply bg-red-500 text-white border-red-500
		@media(max-width: 575.98px)
			@apply overflow-x-scroll
	&-swiper
		.swiper-slide
			@apply h-auto
		.item
			@apply h-full bg-[#F1F6FD]
		.box-img
			img
				border-radius: rr(4px) rr(4px) 0px 0px
		.box-content
			border-radius: 0px 0px rr(4px) rr(4px)
			@apply p-6
		h3
			@apply text-blue-500 global-text-24 font-medium mb-3 transition-all hover:text-red-500
		.desc
			@apply text-black text-lg leading-1.33333
		@media(max-width: 1279.98px)
			@apply pb-5
