.page-banner
	.box-content
		@apply relative
		&::after
			content: ''
			background-image: url('../img/about/7.png')
			background-size: cover
			background-repeat: no-repeat
			background-position: center
			@apply absolute left-0 -bottom-[calc(63/1920*100rem)] w-full h-25
		img, video, iframe
			@apply w-full
		iframe
			height: rr(600px)