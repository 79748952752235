.sustainability-3
	.desc
		@apply text-center my-6 global-text-24 text-black
	&-swiper
		.img-src
			height: rr(137px)
			text-align: center
			img
				@apply h-full object-contain
		@media(max-width: 1279.98px)
			@apply pb-5
