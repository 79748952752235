.home-4
	&-swiper
		@media(max-width: 1279.98px)
			@apply pb-5
	.desc
		@apply text-center my-6 global-text-24 text-black
	.img-src
		height: rr(137px)
		text-align: center
		img
			@apply h-full object-contain
.line-svg
	@apply relative z-10
	svg
		@apply w-full