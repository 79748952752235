.recruitment-our
	.desc
		@apply relative lg:pl-8 global-text-24 text-black
		@screen lg
			&::before
				content: ''
				@apply absolute top-0 left-0 h-full w-[calc(1/1920*100rem)] bg-blue-200
.recruitment-welfare
	.desc
		@apply global-text-24 text-black xl:max-w-[calc(1184/1920*100rem)] mx-auto text-center mb-8
	.item
		@apply bg-[#F1F6FD] flex flex-col items-center text-center px-5 py-8 h-full
		@apply min-h-[calc(264/1920*100rem)]
	.box-content
		@apply mt-3
		h3
			@apply global-text-20 font-semibold text-black
		.desc
			@apply mt-2 global-text-20 leading-1.33333 text-black
	.box-img
		@apply h-[calc(80/1920*100rem)] w-[calc(80/1920*100rem)] rounded-full bg-blue-500
		@apply inline-flex items-center justify-center
	.img-src
		@apply h-[calc(44/1920*100rem)]
		img
			@apply h-full object-contain
.recruitment-list
	.table-wrapper
		table
			@apply w-full
		thead
			tr
				@apply bg-blue-500
			td
				@apply text-white global-text-20 font-medium py-3.5
				&:not(:last-child)
					border-right: 1px solid rgba(255, 255, 255, 0.30)
		tbody
			tr
				&:first-child
					@apply bg-blue-100
				&:not(first-child)
					border-left: 1px solid #E3EDFB
					td
						border-right: 1px solid #E3EDFB
						border-bottom: 1px solid #E3EDFB
			td
				@apply text-lg leading-1.33333 text-black py-3 text-center
				&:nth-child(2)
					@apply pl-5 text-left
					a
						@apply transition-all
						&:hover
							@apply text-red-500
				&:last-child
					a
						@apply text-blue-500
		@media(max-width: 575.98px)
			@apply overflow-auto
			table
				@apply w-[calc(780/1920*100rem)]
	.btn-1
		@apply min-w-fit
