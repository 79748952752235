.sustainability-2
	.box-img, .box-content
		width: 100%
		flex: 0 0 100%
		max-width: 100%
	.box-img
		img
			@apply rounded-1
	.box-content
		@apply rounded-1 flex items-center bg-blue-600
		h3
			@apply global-text-36 font-medium 
		.desc
			@apply global-text-20 mt-6
			@screen lg
				max-height: rr(168px)
				@apply overflow-y-auto pr-2
				&::-webkit-scrollbar
					border-radius: rr(8px)
					width: rr(8px)
					@apply bg-white
				&::-webkit-scrollbar-thumb
					border-radius: rr(8px)
					outline: 1px solid white
					@apply bg-blue-500
	.desc
		@apply global-text-24 my-8
	&-swiper
		.img-src
			img
				@apply rounded-1
		@media(max-width: 1279.98px)
			@apply pb-5
	@screen lg
		.box-img, .box-content
			flex: 0 0 calc(50% - rr(10px))
			max-width: calc(50% - rr(10px))