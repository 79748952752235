.about-3
	.item
		&+.item
			@apply mt-5
	.box-img, .box-content
		width: 100%
		flex: 0 0 100%
		max-width: 100%
	.box-img
		img
			@apply rounded-1
	.box-content
		@apply rounded-1 flex items-center bg-blue-500
		h3
			@apply global-text-48 font-bold
		.desc
			@apply global-text-20 mt-6
			@screen lg
				max-height: rr(168px)
				@apply overflow-y-auto pr-2
				&::-webkit-scrollbar
					border-radius: rr(8px)
					width: rr(8px)
					@apply bg-white
				&::-webkit-scrollbar-thumb
					border-radius: rr(8px)
					outline: 1px solid white
					@apply bg-blue-500
	@screen lg
		.box-img
			flex: 0 0 calc(50% - rr(10px))
			max-width: calc(50% - rr(10px))
		.box-content
			flex: 0 0 calc(50% - rr(10px))
			max-width: calc(50% - rr(10px))
		.item
			&:nth-child(odd)
				.box-img
					@apply order-1
				.box-content
					@apply order-2
			&:nth-child(even)
				.box-img
					@apply order-2
				.box-content
					@apply order-1 bg-blue-600
