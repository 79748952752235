.search-page
	padding: 40px 0
	h1
		letter-spacing: -2px
		margin-bottom: 20px
		font-size: 45px
		line-height: 55px
		font-weight: 500
		color: #333333
		@media (max-width: 1024px)
			font-size: 24px
			line-height: 30px
		&.center
			text-align: center
	.btn-reindex
		display: none
	.searchcontrols
		.form-group
			position: static
		.form-inline
			position: relative
			&:before
				content: '\f002'
				@apply absolute h-12.5 flex-center px-4 font-awesome text-red-500 top-0 right-0
			// .form-group
			// 	&:nth-child(2),&:nth-child(3)
			// 		display: none
			// 	&:nth-child(4)
			// 		position: absolute
			// 		top: 0
			// 		right: 5px
			// 		z-index: 11
			// 		&::before
			// 			content: "\F0CD"
			// 			position: absolute
			// 			top: 50%
			// 			left: 50%
			// 			transform: translate(-50%, -50%)
			// 			// background:
			// 			font-family: remixicon!important
			// 			@apply text-primary-main text-xl
		.frm-btn
			position: absolute
			top: 0
			right: 10px
			background-color: rgba(0,0,0,0)
			z-index: 2
	input[type="text"]
		width: 100%
		height: 100%
		padding-left: 20px
		padding-right: 54px
		border: 1px solid #dfe1e5
		background-color: #fff
		color: #333
		font-size: .875rem
		font-weight: 400
		border-radius: 22px
		box-shadow: 0 0 0 0px rgba(0,0,0,0)
		transition: .25s cubic-bezier(.4,0,.2,1)
		outline: none
		@apply h-12.5
	input[type="submit"]
		@apply absolute top-0 right-0 h-full flex-center px-4 h-12.5 text-0
	.text-danger
		margin-top: 20px
		font-size: 15px
		color: #333
		font-style: italic
		font-weight: 600
	.searchresultsummary
		margin-bottom: 20px
	.searchresults
		margin-top: 30px
		.modulepager
			&:first-child
				display: none
	.searchresultlist
		margin-bottom: 20px
	.searchresult
		margin-bottom: 30px
		box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28)
		padding: 16px
		border-radius: 8px
		&:last-child
			margin-bottom: 0
		h3
			font-size: 18px
			line-height: 1.33
			font-weight: 400
			margin-bottom: 10px
			a
				color: #1A0DAB
				text-decoration: none
				font-weight: 500
				&:hover
					text-decoration: underline
		.searchresultdesc
			color: #545454
			line-height: 1.54
			word-wrap: break-word
			font-size: small
			font-weight: 400
			margin-bottom: 10px
		.searchterm
			color: #6a6a6a
			font-weight: bold
			font-style: normal
			line-height: 1.54
			word-wrap: break-word
			font-size: small
		hr
			display: none !important
