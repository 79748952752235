.product-list
	.box-top
		&.section-medium
			@apply pb-0
		&-swiper
			@apply -mx-2.5
			.swiper
				@apply px-2.5
			.btn-prev, .btn-next
				top: 24%
				@apply -translate-y-0
			@media(max-width: 1279.98px)
				@apply pb-5
		.desc
			@apply global-text-24 text-[#151515] mt-8
		.item-product-cate
			@apply pt-8
			&:hover, &.active
				.box-img
					box-shadow: 3px 3px 8px -2px rgb(0, 0, 0)
					@apply -translate-y-5
	.box-bottom
		@apply overflow-hidden
		ul
			@apply flex items-center justify-center gap-5 my-5
			li
				&.active
					a
						@apply bg-red-500 text-white border-red-500
			a
				@apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20
				@apply inline-flex items-center justify-center py-2 px-2.5
				@apply transition-all duration-300
				&:hover
					@apply bg-red-500 text-white border-red-500
		.desc
			@apply global-text-24 text-[#151515] mb-5

.item-product-cate
	@apply transition-all duration-300
	&:hover, &.active
		.content
			h3
				@apply text-red-500
				&::after
					@apply opacity-100
	.box-img
		border-radius: rr(8px)
		background: rgba(204, 204, 204, 0.50)
		padding: rr(5px) rr(15px)
		@apply text-center transition-all duration-300
		img
			border-radius: rr(8px)
			@apply transition-all duration-300
	.content
		@apply mt-5 text-center
		h3
			@apply text-28 leading-1.33333 font-medium flex flex-col gap-1 capitalize
			@apply transition-all duration-300
			&::after
				content: '\f0d7'
				@apply font-black text-2xl leading-1.33333 font-awesome text-red-500
				@apply transition-all duration-300 opacity-0

.item-product
	&:hover
		h3
			@apply text-red-500
	.box-content
		@apply mt-3.75
	h3
		@apply global-text-24 text-black font-medium capitalize
		@apply transition-all duration-300
