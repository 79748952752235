.about-2
	@media(max-width: 767.98px)
		@apply mb-10
	.swiper-thumb
		&::before
			content: ''
			@apply absolute top-[21%] left-1/2 -translate-x-1/2
			@apply bg-blue-100 w-full h-[calc(2/1920*100rem)]
		.icon
			@apply h-8 w-8 rounded-full border-blue-500 relative bg-white
			@apply border cursor-pointer scale-[77%] mx-auto
			@apply transition-all duration-300
			&::before
				content: ''
				@apply h-2 w-2 rounded-full bg-blue-500 scale-[60%]
				@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
				@apply transition-all duration-300
		.year
			@apply global-text-20 font-medium text-blue-400 text-center mt-2.25
		.swiper-slide-thumb-active
			.icon
				@apply scale-100 border-red-500
				&::before
					@apply bg-red-500
			.year
				@apply text-red-500
	.swiper-main
		@apply pb-5 -m-8
		.wrapper
			@apply overflow-hidden p-8
		.swiper-slide
			@apply transition-all duration-300 opacity-40
			@screen xl
				&:hover
					@apply opacity-100 scale-115
		h3
			@apply text-center text-red-500 global-text-32 font-semibold mt-5
		.desc
			@apply global-text-20 text-[#151515] text-center
		.img-src
			img
				@apply rounded-1
		@screen md
			.swiper
				// @apply w-[calc(100%/2-32px)] overflow-visible ml-0
				@apply overflow-visible ml-0
		@screen xl
			@apply pb-0
			.swiper
				// @apply w-[calc(100%/3-64px)]
		@media(max-width: 1279.98px)
			.swiper-slide-active
				@apply opacity-100