@charset "UTF-8";
.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype");
}
.fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands";
  font-weight: 400;
}

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype");
}
.fal,
.fa-light {
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
}

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype");
}
.far,
.fa-regular {
  font-family: "Font Awesome 6 Pro";
  font-weight: 400;
}

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype");
}
.fas,
.fa-solid {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
}

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype");
}
.fat,
.fa-thin {
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/SVN-GothamBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gotham";
  src: url("../fonts/SVN-GothamLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
form.invalid .wpcf7-response-output {
  @apply text-red-600 border-red-600 bg-white !important;
}

.wpcf7-response-output {
  @apply text-green-400 border-green-400 bg-white border py-1 px-4 text-center mt-2 mb-0 ml-0 mr-0 text-sm !important;
}

.form-group {
  @apply relative;
}
.form-group .wpcf7-not-valid-tip {
  @apply absolute top-1/2 -translate-y-1/2 right-3 text-xs text-red-600;
}

.frm-submit {
  @apply relative;
}
.frm-submit .wpcf7-spinner {
  @apply absolute left-full top-1/2 -translate-y-1/2 ml-2;
}

.edit-link i:before {
  content: "✏️";
}

@media (max-width: 575.89px) {
  .fancybox__content > .carousel__button.is-close {
    right: 0 !important;
    top: -34px !important;
  }
}

.wrap-button-slide {
  display: flex;
  align-items: center;
}
.wrap-button-slide .btn-prev, .wrap-button-slide .btn-next {
  @apply flex-center cursor-pointer;
  @apply transition-all duration-300;
}
.wrap-button-slide .btn-prev:before, .wrap-button-slide .btn-next:before {
  @apply font-awesome text-xl;
  @apply transition-all duration-300;
}
.wrap-button-slide .btn-prev:before {
  content: "\f053";
}
.wrap-button-slide .btn-next:before {
  content: "\f054";
}
.wrap-button-slide .swiper-button-disabled {
  @apply opacity-60;
}
.wrap-button-slide.slide-default {
  @apply gap-5;
}
.wrap-button-slide.slide-default .btn-prev, .wrap-button-slide.slide-default .btn-next {
  @apply rounded-full border border-blue-200 bg-transparent w-10 h-10 md:w-15 md:h-15;
}
.wrap-button-slide.slide-default .btn-prev::before, .wrap-button-slide.slide-default .btn-next::before {
  @apply text-blue-500;
}
.wrap-button-slide.slide-default .btn-prev:hover, .wrap-button-slide.slide-default .btn-next:hover {
  @apply bg-red-500 border-red-500;
}
.wrap-button-slide.slide-default .btn-prev:hover::before, .wrap-button-slide.slide-default .btn-next:hover::before {
  @apply text-white;
}
.wrap-button-slide.slide-style-1 .btn-prev, .wrap-button-slide.slide-style-1 .btn-next {
  @apply absolute top-1/2 -translate-y-1/2;
  @apply rounded-full border border-blue-200 bg-transparent w-15 h-15;
}
.wrap-button-slide.slide-style-1 .btn-prev::before, .wrap-button-slide.slide-style-1 .btn-next::before {
  @apply text-blue-500;
}
.wrap-button-slide.slide-style-1 .btn-prev:hover, .wrap-button-slide.slide-style-1 .btn-next:hover {
  @apply bg-red-500 border-red-500;
}
.wrap-button-slide.slide-style-1 .btn-prev:hover::before, .wrap-button-slide.slide-style-1 .btn-next:hover::before {
  @apply text-white;
}
.wrap-button-slide.slide-style-1 .btn-prev {
  @apply -left-[calc(92/1920*100rem)];
}
.wrap-button-slide.slide-style-1 .btn-next {
  @apply -right-[calc(92/1920*100rem)];
}
@media (max-width: 1279.98px) {
  .wrap-button-slide.slide-style-1 {
    @apply hidden;
  }
}
.wrap-button-slide.slide-style-2 .btn-prev, .wrap-button-slide.slide-style-2 .btn-next {
  @apply absolute top-1/2 -translate-y-1/2;
  @apply rounded-full border border-blue-200 bg-white w-15 h-15 z-10;
}
.wrap-button-slide.slide-style-2 .btn-prev::before, .wrap-button-slide.slide-style-2 .btn-next::before {
  @apply text-blue-500;
}
.wrap-button-slide.slide-style-2 .btn-prev:hover, .wrap-button-slide.slide-style-2 .btn-next:hover {
  @apply bg-red-500 border-red-500;
}
.wrap-button-slide.slide-style-2 .btn-prev:hover::before, .wrap-button-slide.slide-style-2 .btn-next:hover::before {
  @apply text-white;
}
.wrap-button-slide.slide-style-2 .btn-prev {
  @apply left-5;
}
.wrap-button-slide.slide-style-2 .btn-next {
  @apply right-5;
}
@media (max-width: 1279.98px) {
  .wrap-button-slide.slide-style-2 {
    @apply hidden;
  }
}
.wrap-button-slide.slide-style-3 .btn-prev, .wrap-button-slide.slide-style-3 .btn-next {
  @apply absolute top-1/2 -translate-y-1/2;
  @apply border border-blue-200 bg-white w-12 h-12 z-10;
}
.wrap-button-slide.slide-style-3 .btn-prev::before, .wrap-button-slide.slide-style-3 .btn-next::before {
  @apply text-blue-500;
}
.wrap-button-slide.slide-style-3 .btn-prev:hover, .wrap-button-slide.slide-style-3 .btn-next:hover {
  @apply bg-red-500 border-red-500;
}
.wrap-button-slide.slide-style-3 .btn-prev:hover::before, .wrap-button-slide.slide-style-3 .btn-next:hover::before {
  @apply text-white;
}
.wrap-button-slide.slide-style-3 .btn-prev {
  @apply left-0;
}
.wrap-button-slide.slide-style-3 .btn-next {
  @apply right-0;
}

.wrap-navigation-slide.progressbar-style-1 .swiper-pagination {
  @apply text-center flex flex-col gap-2.5;
  @apply left-0 bottom-[calc(36/1920*100rem)];
}
@screen xl {
  .wrap-navigation-slide.progressbar-style-1 .swiper-pagination {
    @apply bottom-[calc(88/1920*100rem)];
  }
}
.wrap-navigation-slide.progressbar-style-1 .swiper-pagination-bullet {
  width: 0.15625rem;
  height: 0.78125rem;
  @apply bg-white opacity-50 rounded-25 transition-all duration-300;
}
.wrap-navigation-slide.progressbar-style-1 .swiper-pagination-bullet-active {
  height: 1.5625rem;
  @apply opacity-100;
}
@screen md {
  .wrap-navigation-slide.progressbar-style-1 .swiper-pagination-bullet {
    height: 1.5625rem;
  }
  .wrap-navigation-slide.progressbar-style-1 .swiper-pagination-bullet-active {
    height: 3.125rem;
  }
}
.wrap-navigation-slide.progressbar-style-2 .swiper-pagination {
  @apply bottom-0;
}
.wrap-navigation-slide.progressbar-style-2 .swiper-pagination-bullet-active {
  @apply bg-blue-500;
}

.swiper-button-lock {
  @apply hidden !important;
}

.zoom-img {
  overflow: hidden;
}
.zoom-img:hover img {
  transform: scale(1.05) translateZ(0);
}
.zoom-img img {
  transition: 0.5s all ease-in-out !important;
}

.ajaxResponse > div {
  animation: 0.5s 0.25s fade-in-up ease-in-out both;
}

.ring-food {
  animation: 1.5s ring-food infinite ease-in-out alternate;
}

.ring-chili {
  animation: 1.5s ring-chili infinite ease-in-out alternate;
}

.ring-food-footer {
  animation: 1.5s ring-food-footer infinite ease-in-out alternate;
}

@keyframes ring-chili {
  0% {
    transform: rotate(8deg);
  }
  100% {
    transform: rotate(-8deg);
  }
}
@keyframes ring-food-footer {
  0% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes ring-food {
  0% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(330deg);
  }
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.AspNet-TreeView {
  line-height: 1.5;
}
.AspNet-TreeView > ul {
  columns: 3 auto;
  margin: 10px 0 0;
  padding-bottom: 25px;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root {
  margin-bottom: 26px;
  break-inside: avoid-column;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root > a {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 7px;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
  display: none;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li {
  padding-bottom: 7px;
  font-size: 18px;
  color: #06c;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li > a {
  font-weight: 500;
  display: inline-block;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > a {
  margin-bottom: 7px;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul {
  list-style: disc;
  padding-left: 1.25em;
}
.AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul > li {
  font-size: 16px;
  margin-bottom: 7px;
}
@media screen and (max-width: 768.98px) {
  .AspNet-TreeView > ul {
    column-count: 1;
  }
}

.sitemap {
  padding: 2.5rem 0;
}
.sitemap a:hover {
  text-decoration: underline;
}

.sitemap-heading {
  height: 3.25rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.sitemap-heading h1 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.5;
}

@layer base {
  html, body {
    font-size: 15px;
    scroll-behavior: smooth;
  }
  @screen md {
    html, body {
      font-size: 15px;
    }
  }
  @screen xl {
    html, body {
      font-size: 1vw;
    }
  }
  .table-responsive {
    @apply overflow-auto;
  }
  @media (max-width: 767.98px) {
    .table-responsive table {
      width: 900px !important;
    }
  }
  .img-full img {
    @apply w-full h-full object-cover;
  }
  .img-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .img-ratio img, .img-ratio iframe {
    @apply absolute top-0 left-0 w-full h-full object-cover transition-all duration-300;
  }
  .img-ratio-center {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    @apply flex-center;
  }
  .img-ratio-center img, .img-ratio-center iframe {
    @apply absolute top-1/2 left-1/2 w-auto h-auto object-contain transition-all duration-300 max-w-full max-h-full -translate-x-1/2 -translate-y-1/2;
  }
  .div-ratio {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%;
  }
  .div-ratio > div {
    @apply absolute top-0 left-0 w-full h-full transition-all duration-300;
  }
  body {
    @apply text-black xl:text-base font-primary;
  }
  body::-webkit-scrollbar {
    width: 0px;
  }
  .row {
    @apply flex flex-wrap -mx-[calc(16/1920*100rem)];
  }
  .col {
    @apply px-[calc(16/1920*100rem)] w-full;
  }
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold;
  }
  img {
    @apply inline max-w-full;
  }
  video, iframe {
    @apply max-w-full;
  }
  a {
    @apply transition-all ease-linear duration-200;
  }
  p {
    @apply empty:hidden;
  }
  input {
    @apply outline-offset-0 shadow-none ring-0 !important;
  }
  .section-40 {
    @apply lg:py-10 py-6;
  }
  .margin-p p + p {
    @apply mt-5;
  }
  .overflow-scroll-1::-webkit-scrollbar {
    @apply w-[calc(6/1920*100rem)];
  }
  .overflow-scroll-1::-webkit-scrollbar-track {
    background-color: rgba(229, 229, 229, 0.2);
  }
  .overflow-scroll-1::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }
  .overlay-black::before {
    content: "";
    @apply absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 pointer-events-none z-10 whitespace-nowrap;
  }
  main {
    @apply text-base pt-25;
  }
  @media (max-width: 767.98px) {
    main main {
      @apply pt-20;
    }
  }
  .homepage main {
    @apply pt-0;
  }
  @media (max-width: 767.98px) {
    .homepage main {
      @apply pt-20;
    }
  }
}
@layer utilities {
  .container-custom-1500 {
    max-width: 1560px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @screen lg {
    .container-custom-1500 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .container-custom-1172 {
    max-width: 1232px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  @screen 2xl {
    .container-custom-1172 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .global-text-48 {
    @apply text-32 leading-1.166666;
  }
  .global-text-40 {
    @apply text-28 leading-1.2;
  }
  .global-text-36 {
    @apply text-4xl leading-1.166666;
  }
  .global-text-32 {
    @apply text-32 leading-1.25;
  }
  .global-text-24 {
    @apply text-2xl leading-1.33333;
  }
  .global-text-20 {
    @apply text-xl leading-1.4;
  }
  .global-text-16 {
    @apply text-base leading-1.5;
  }
  @screen md {
    .global-text-48 {
      @apply text-6xl;
    }
    .global-text-40 {
      @apply text-5xl;
    }
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
.loginpage .panel-body .flex.justify-between.mt-3 {
  display: none;
}
.loginpage header {
  display: none;
}
.loginpage footer {
  display: none;
}
.loginpage main {
  background-color: #F0F0F1;
  display: table-cell;
  vertical-align: middle;
  height: 100vh;
  width: 100vw;
  padding-top: 0 !important;
  padding: 0 15px;
}
.loginpage .header-tools-mobile {
  display: none !important;
}
.loginpage .login-box {
  max-width: 690px;
  margin: 0 auto;
  width: 100%;
}
.loginpage .login-box .login-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  flex-direction: column;
  font-size: 0;
  margin-bottom: 30px;
}
.loginpage .login-box .login-logo::before {
  content: "";
  display: inline-block;
  background-image: url("/Data/Sites/1/skins/default/img/logo-canh-cam.png");
  width: 267px;
  height: 49px;
  background-repeat: no-repeat;
  background-size: 267px auto;
  margin-bottom: 15px;
}
.loginpage .login-box .loginstandard {
  background-color: #fff;
  box-shadow: 0, 4px, 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 0;
  padding: 40px 60px 30px;
  font-weight: 400;
  overflow: hidden;
  font-family: Roboto, sans-serif !important;
}
@media (max-width: 767.98px) {
  .loginpage .login-box .loginstandard {
    padding: 15px 20px;
  }
}
.loginpage .login-box .loginstandard .card-body .row.items-center {
  @apply flex-wrap mx-0;
}
.loginpage .login-box .loginstandard .card-body .row.items-center .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.loginpage .login-box .loginstandard .card-body .login-box-msg {
  display: none;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group {
  position: relative;
  margin-bottom: 1.25rem;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group .input-group-append {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  color: #d1d1d1;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input {
  background: #f5f5f5;
  border-radius: 25px;
  height: 50px;
  border: 0;
  outline: none;
  box-shadow: 0 0 0 1px #f5f5f5;
  transition: 0.3s all ease-in-out;
  padding: 0 35px;
  font-size: 14px;
  width: 100%;
  border: 1px solid transparent;
}
.loginpage .login-box .loginstandard .card-body .panel-body .input-group input:hover, .loginpage .login-box .loginstandard .card-body .panel-body .input-group input:focus {
  border: 1px solid #f73936;
}
.loginpage .login-box .loginstandard .card-body .panel-body .icheck-primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  margin-bottom: 20px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .btn-primary {
  background-color: #f73936;
  border-radius: 25px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  font-size: 20px;
  border: 0;
  outline: none;
  transition: 0.3s all ease-in-out;
}
.loginpage .login-box .loginstandard .card-body .panel-body .flex.justify-between.mt-3 {
  margin-top: 30px;
  font-size: 14px;
}
.loginpage .login-box .loginstandard .card-body .panel-body .cart-login {
  display: none;
}
.loginpage .login-box .loginstandard .card-body span.alert {
  color: #f73936;
  display: block;
  margin-bottom: 10px;
  text-align: center;
}

span.fa-exclamation-triangle {
  @apply text-red-500 absolute top-1/2 right-3 -translate-y-1/2 text-xs md:text-sm font-primary font-normal;
}
span.fa-exclamation-triangle::before {
  display: none;
}

.btn.btn-default.frm-btn-reset {
  @apply hidden;
}

.wrap-form-submit {
  @apply flex items-center flex-wrap justify-between gap-5 md:gap-4;
}
.wrap-form-submit .frm-captcha, .wrap-form-submit .frm-btnwrap {
  opacity: 1;
}
.wrap-form-submit .frm-captcha {
  @apply flex flex-row-reverse items-center flex-1;
}
.wrap-form-submit .frm-captcha .RadCaptcha {
  width: fit-content !important;
  @apply relative;
}
.wrap-form-submit .frm-captcha .RadCaptcha > span {
  @apply absolute top-full left-0 text-red-500;
}
.wrap-form-submit .frm-captcha .RadCaptcha > div > div {
  @apply flex items-center;
}
.wrap-form-submit .frm-captcha .RadCaptcha img {
  width: 8.28125rem !important;
  height: 2.6041666667rem !important;
  @apply border border-[#EEEEEE] !important;
}
.wrap-form-submit .frm-captcha .RadCaptcha a.rcRefreshImage {
  @apply text-0 before:content-[""] before:font-awesome before:text-2xl before:mx-3 before:inline-block w-full h-full text-body-text-33;
  @apply flex-center !important;
}
.wrap-form-submit .frm-captcha .frm-captcha-input {
  @apply flex-1;
}
.wrap-form-submit .frm-captcha .frm-captcha-input label {
  @apply hidden;
}
@media (max-width: 767.98px) {
  .wrap-form-submit .frm-btnwrap {
    @apply flex-[0_0_100%];
  }
}
.wrap-form-submit .frm-btnwrap label {
  @apply hidden;
}

.RadUpload {
  width: auto !important;
}

.search-page {
  padding: 40px 0;
}
.search-page h1 {
  letter-spacing: -2px;
  margin-bottom: 20px;
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  color: #333333;
}
@media (max-width: 1024px) {
  .search-page h1 {
    font-size: 24px;
    line-height: 30px;
  }
}
.search-page h1.center {
  text-align: center;
}
.search-page .btn-reindex {
  display: none;
}
.search-page .searchcontrols .form-group {
  position: static;
}
.search-page .searchcontrols .form-inline {
  position: relative;
}
.search-page .searchcontrols .form-inline:before {
  content: "\f002";
  @apply absolute h-12.5 flex-center px-4 font-awesome text-red-500 top-0 right-0;
}
.search-page .searchcontrols .frm-btn {
  position: absolute;
  top: 0;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}
.search-page input[type=text] {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 54px;
  border: 1px solid #dfe1e5;
  background-color: #fff;
  color: #333;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 22px;
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
  @apply h-12.5;
}
.search-page input[type=submit] {
  @apply absolute top-0 right-0 h-full flex-center px-4 h-12.5 text-0;
}
.search-page .text-danger {
  margin-top: 20px;
  font-size: 15px;
  color: #333;
  font-style: italic;
  font-weight: 600;
}
.search-page .searchresultsummary {
  margin-bottom: 20px;
}
.search-page .searchresults {
  margin-top: 30px;
}
.search-page .searchresults .modulepager:first-child {
  display: none;
}
.search-page .searchresultlist {
  margin-bottom: 20px;
}
.search-page .searchresult {
  margin-bottom: 30px;
  box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
  padding: 16px;
  border-radius: 8px;
}
.search-page .searchresult:last-child {
  margin-bottom: 0;
}
.search-page .searchresult h3 {
  font-size: 18px;
  line-height: 1.33;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult h3 a {
  color: #1A0DAB;
  text-decoration: none;
  font-weight: 500;
}
.search-page .searchresult h3 a:hover {
  text-decoration: underline;
}
.search-page .searchresult .searchresultdesc {
  color: #545454;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
  font-weight: 400;
  margin-bottom: 10px;
}
.search-page .searchresult .searchterm {
  color: #6a6a6a;
  font-weight: bold;
  font-style: normal;
  line-height: 1.54;
  word-wrap: break-word;
  font-size: small;
}
.search-page .searchresult hr {
  display: none !important;
}

.h-full-module [class*=Module] {
  height: 100%;
}

.notfound .notfound-404 h1 {
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to right, #ed1b24, #bc0049) !important;
  display: inline-block;
}

.notfound a {
  background: linear-gradient(90deg, #ed1b24, #bc0049) !important;
}

.mobile-only {
  @apply block lg:hidden;
}

.desktop-only {
  @apply hidden lg:block;
}

.header-search-form {
  visibility: hidden;
  @apply fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 backdrop-blur-md z-[1000] flex justify-center items-center transition-all duration-300 opacity-0  pointer-events-none;
}
.header-search-form .close {
  @apply right-0;
}
.header-search-form.active {
  @apply opacity-100 visible pointer-events-auto;
}
.header-search-form .wrap-form-search-product {
  max-width: 50rem;
  margin: 0 auto;
  @apply w-full;
}
.header-search-form .wrap-form-search-product .productsearchbox {
  @apply w-full flex relative flex-wrap;
}
.header-search-form .wrap-form-search-product .productsearchbox [class*=Module] {
  width: 100% !important;
}
.header-search-form .wrap-form-search-product .productsearchbox .searchbox {
  @apply w-full flex;
}
.header-search-form .wrap-form-search-product .productsearchbox input {
  @apply flex-1 h-[calc(50/1920*100rem)] bg-white text-base px-6 outline-0 border-0 !important;
}
.header-search-form .wrap-form-search-product .productsearchbox button {
  @apply w-9 flex items-center justify-center bg-white text-[0px] outline-0 border-0 border-l border-stroke-E0;
}
.header-search-form .wrap-form-search-product .productsearchbox button::before {
  content: "\f002";
  font-family: "Font Awesome 6 Pro";
  color: #222222;
  @apply text-base;
}

.btn {
  @apply inline-flex items-center justify-center;
}
.btn.btn-1 {
  @apply gap-3 px-6 py-2 rounded-[calc(8/1920*100rem)] bg-blue-100 global-text-20 text-blue-500 min-w-[calc(195/1920*100rem)];
  @apply transition-all duration-300;
}
.btn.btn-1:hover {
  @apply text-white bg-red-500;
}
.btn.btn-2 {
  @apply gap-3 px-6 py-2 rounded-[calc(8/1920*100rem)] bg-red-500 global-text-20 text-white min-w-[calc(171/1920*100rem)];
}
.btn.btn-3 {
  @apply gap-3 px-7.5 py-2 rounded-[calc(8/1920*100rem)] bg-white global-text-20 text-blue-500 min-w-[calc(304/1920*100rem)];
  @apply transition-all duration-300;
}
.btn.btn-3:hover {
  @apply text-white bg-red-500;
}

.pagination {
  @apply flex-center gap-3 mt-8;
}
.pagination li.active a, .pagination li.active span {
  @apply bg-white text-red-500 border-red-500;
}
.pagination a, .pagination span {
  @apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20;
  @apply inline-flex justify-center items-center w-11 h-11;
  @apply transition-all duration-300;
}
.pagination a:hover, .pagination span:hover {
  @apply bg-red-500 text-white border-red-500;
}
.pagination a.FirstPage, .pagination a.BackPage, .pagination a.NextPage, .pagination a.LastPage, .pagination span.FirstPage, .pagination span.BackPage, .pagination span.NextPage, .pagination span.LastPage {
  @apply hidden;
}

.fancybox__content {
  @apply p-0;
}
.fancybox__content .is-close {
  right: -2.2916666667rem !important;
  @apply top-0 bg-red-500 rounded-none w-11 h-11 !important;
}

.section-large {
  @apply py-10;
}
@screen md {
  .section-large {
    @apply py-25;
  }
}

.section-medium {
  @apply py-10;
}
@screen md {
  .section-medium {
    @apply py-20;
  }
}

.section-small {
  @apply py-7.5;
}
@screen md {
  .section-small {
    @apply py-15;
  }
}

.title-global-1 {
  @apply text-secondary-1 relative w-fit mb-[calc(44/1920*100rem)] uppercase;
}
.title-global-1::before {
  content: "";
  top: calc(100% + 9px);
  @apply absolute left-0 w-full h-1  rounded-[calc(5/1920*100rem)];
}

.social-fixed li:nth-child(1) a {
  @apply bg-blue-500 h-[calc(48/1920*100rem)];
}
.social-fixed li:nth-child(3) a, .social-fixed li:nth-child(2) a {
  @apply p-3 h-[calc(72/1920*100rem)] border-stroke-CC border;
}
.social-fixed li:nth-child(3) img, .social-fixed li:nth-child(2) img {
  @apply h-[calc(48/1920*100rem)] object-contain;
}
.social-fixed a {
  @apply flex-center flex-col w-[calc(72/1920*100rem)] text-white rounded-[calc(8/1920*100rem)] py-2 bg-white;
}
.social-fixed a em {
  @apply text-2xl;
}
.social-fixed a span {
  @apply text-[calc(12/1920*100rem)] leading-1.5 mt-1;
}

.social-fixed-wrapper .ActionMenuBody {
  left: unset !important;
  @apply right-0;
}

.about-page .home-5 .img-src {
  @apply xl:scale-100;
}

.contact-page .contact-left {
  @apply lg:pr-20;
}
.contact-page .contact-left h1 {
  font-size: 2.9166666667rem;
  @apply font-secondary font-normal text-red-500 leading-1.25 relative pb-6 mb-5;
}
.contact-page .contact-left h1 span {
  @apply text-blue-500 global-text-48 font-thin block font-primary;
}
.contact-page .contact-left h1::after {
  content: "";
  @apply h-[calc(1/1920*100rem)] w-full absolute bottom-0 left-0 bg-stroke-CC;
}
.contact-page .contact-left .item {
  @apply py-5 relative;
}
.contact-page .contact-left .item::after {
  content: "";
  @apply h-[calc(1/1920*100rem)] w-full absolute bottom-0 left-0 bg-stroke-CC;
}
.contact-page .contact-left .item li {
  @apply flex gap-5 md:gap-3;
}
.contact-page .contact-left .item li + li {
  @apply mt-2;
}
.contact-page .contact-left .desc {
  @apply text-lg text-body-text-33 leading-1.33333;
}
.contact-page .contact-left .desc > a {
  @apply underline text-[#008BFF];
}
.contact-page .contact-left .icon {
  flex: 0 0 4.2857142857%;
  max-width: 4.2857142857%;
  width: 100%;
  height: 1.25rem;
}
.contact-page .contact-left .icon em {
  @apply text-2xl leading-none text-blue-500;
}
.contact-page .contact-left .location-company {
  @apply global-text-24 font-medium mb-4;
}
.contact-page .contact-right {
  @apply bg-[#F1F6FD] px-8 py-11 lg:-mr-8;
}
.contact-page .form-title {
  @apply text-center global-text-20;
}
.contact-page .form-title span {
  @apply text-blue-500;
}
.contact-page .form-contact .form-group {
  @apply mt-3;
}
.contact-page .form-contact .frm-btnwrap {
  @apply flex justify-center;
}
.contact-page .form-contact input:not([type=submit]), .contact-page .form-contact textarea {
  @apply w-full resize-none rounded-1;
}
.contact-page .form-contact input:not([type=submit])::placeholder, .contact-page .form-contact textarea::placeholder {
  @apply text-body-text-66 text-lg leading-1.33333;
}
.contact-page .form-contact input:not([type=submit]) {
  @apply px-5 py-3 text-body-text-66 text-lg leading-1.33333;
}
.contact-page .form-contact input[type=submit] {
  @apply text-0 absolute cursor-pointer inset-0 !important;
}
.contact-page .form-contact textarea {
  @apply h-[calc(112/1920*100rem)] pt-3 pl-5;
}
.contact-page .form-contact .frm-btn {
  @apply bg-red-500 inline-flex items-center gap-3 px-15 h-11 rounded-[calc(8/1920*100rem)] relative;
}
.contact-page .form-contact .frm-btn::before {
  content: "Send";
  @apply text-xl font-normal text-white leading-[28/20];
}
.contact-page .form-contact .frm-btn::after {
  content: "\f178";
  @apply font-awesome text-xl leading-none text-white font-light;
}

.post-detail {
  font-family: League Spartan;
}
.post-detail .post-title {
  color: #08518F;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1666666667;
}
.post-detail .full-content p {
  @apply global-text-20 text-black;
}
.post-detail .full-content p:first-child {
  @apply font-medium;
}
.post-detail .full-content p + p {
  @apply mt-5;
}
.post-detail .full-content p img {
  @apply w-full object-cover;
}
.post-detail .full-content a {
  @apply text-red-500;
}
.post-detail .full-content iframe {
  @apply inline;
}
.post-detail .post-left {
  @apply relative;
}
.post-detail .post-left .post-date {
  @apply flex my-6 text-body-text-99 gap-2 relative;
}
.post-detail .post-left .post-date em {
  @apply leading-1.25;
}
.post-detail .post-left .post-date p {
  @apply leading-1.5;
}
.post-detail .post-left .post-date::after {
  content: "";
  width: calc(100% - 6.25rem);
  @apply absolute top-1/2 right-0;
  @apply h-[calc(1/1920*100rem)] bg-blue-500 -translate-y-1/2;
}
.post-detail .post-right h2 {
  @apply global-text-24 text-red-500 mb-5;
}
.post-detail .showListPostNews + .showListPostNews {
  @apply mt-4;
}
.post-detail .showListPostNews:hover .post-list-title::before {
  @apply bg-red-500;
}
.post-detail .showListPostNews:hover h3 {
  @apply text-red-500;
}
.post-detail .showListPostNews .post-image, .post-detail .showListPostNews .post-list-title {
  @apply w-full;
}
.post-detail .showListPostNews .post-image {
  flex: 0 0 33.1730769231%;
  max-width: 33.1730769231%;
  height: 4.7916666667rem;
}
.post-detail .showListPostNews .post-image > a {
  @apply block w-full h-full;
}
.post-detail .showListPostNews .post-image img {
  @apply w-full object-cover h-full;
}
.post-detail .showListPostNews .post-list-title {
  flex: 0 0 62.7403846154%;
  max-width: 62.7403846154%;
  @apply relative pt-2;
}
.post-detail .showListPostNews .post-list-title::before {
  content: "";
  @apply absolute top-0 left-0 w-full h-[calc(2/1920*100rem)] bg-transparent;
  @apply transition-all duration-300;
}
.post-detail .showListPostNews .post-date {
  @apply flex text-body-text-99 gap-2;
}
.post-detail .showListPostNews .post-date em {
  @apply leading-1.25;
}
.post-detail .showListPostNews .post-date p {
  @apply leading-1.5;
}
.post-detail .showListPostNews h3 {
  @apply global-text-20 text-black font-normal line-clamp-2;
}

.social-share {
  @apply mt-8;
}
.social-share a {
  @apply inline-flex items-center justify-center w-12 h-12 rounded-full bg-blue-50;
  @apply text-2xl text-blue-500;
}
@screen xl {
  .social-share {
    left: -4.1666666667rem;
    @apply absolute top-0 mt-0;
  }
}

.news-detail-page .show-news {
  @apply block;
}

.global-breadcrumb {
  @apply bg-background-F5;
}
.global-breadcrumb ol {
  @apply flex items-center py-2;
}
@media (max-width: 566.98px) {
  .global-breadcrumb ol {
    @apply overflow-x-auto;
  }
}
.global-breadcrumb li:first-child a {
  @apply text-0;
}
.global-breadcrumb li:first-child a::before {
  content: "\f015";
  @apply font-awesome leading-1.25 text-base;
}
.global-breadcrumb li:not(:first-child)::before {
  content: "\f105";
  @apply font-awesome text-[#777777] mx-2;
}
.global-breadcrumb li a {
  @apply text-[#777777] text-lg leading-1.33333 whitespace-nowrap;
}

footer {
  @apply bg-[#e4edfb];
}
footer::before {
  content: "";
  background-image: url("../img/home/7.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @apply absolute left-0 -top-6 w-full h-[calc(230/1920*100rem)] z-10 md:h-[calc(160/1920*100rem)];
}
footer h3 {
  @apply text-black global-text-24 font-normal mb-3;
}
footer .footer-mail {
  @apply text-xl leading-1.4 text-body-text-33 flex gap-3;
}
footer .footer-socials {
  @apply flex gap-2.5;
}
footer .footer-socials a {
  @apply inline-flex items-center justify-center h-12 w-12 rounded-full bg-white;
  @apply text-blue-500 text-2xl;
}
footer .footer-links {
  @apply flex items-center gap-8.5 mb-3;
}
footer .footer-links a {
  @apply text-body-text-66 leading-[calc(28/16)];
}
footer .copy-right {
  @apply text-body-text-66;
}
footer .logo-bct {
  @apply mt-4 w-[calc(158/1920*100rem)];
}
footer .logo-bct img {
  @apply w-full object-contain;
}
footer .subscribe {
  @apply mt-3;
}
footer .subscribe .subscribefrm {
  @apply bg-white rounded-[calc(8/1920*100rem)] relative max-w-[calc(304/1920*100rem)] py-2 px-7.5;
  @apply transition-all duration-300;
}
footer .subscribe .subscribefrm:hover {
  @apply bg-red-500;
}
footer .subscribe .subscribefrm:hover input {
  @apply text-white bg-red-500;
}
footer .subscribe .subscribefrm:hover input::placeholder {
  @apply text-white;
}
footer .subscribe .subscribefrm:hover button i {
  @apply text-white;
}
footer .subscribe input {
  @apply text-blue-500 global-text-20 w-full pr-7 bg-white;
  @apply transition-all duration-300;
}
footer .subscribe input::placeholder {
  @apply text-blue-500 global-text-20;
  @apply transition-all duration-300;
}
footer .subscribe input:focus {
  outline: none;
}
footer .subscribe button {
  @apply absolute top-1/2 -translate-y-1/2 right-7.5 flex-center;
}
footer .subscribe button i {
  @apply text-blue-500 text-xl leading-none;
  @apply transition-all duration-300;
}
footer .subscribe span {
  @apply absolute top-[130%];
}
@media (max-width: 1023.98px) {
  footer .row .col:last-child {
    @apply mt-7.5;
  }
}
@media (max-width: 767.98px) {
  footer .row .col + .col {
    @apply mt-7.5;
  }
}

body {
  --menu: #08518F;
  --menu-active: #C4212B;
  --border: #CCCCCC;
  --background: white;
}

.homepage {
  --menu: #08518F;
  --menu-active: #C4212B;
  --border: #CCCCCC;
  --background: white;
}
@screen xl {
  .homepage {
    --menu: white;
    --border: white;
    --background: transparent;
    --menu-active: white;
  }
}
.homepage .header-logo a.header-logo-desktop {
  @apply hidden;
}
.homepage .header-logo a.header-logo-mobile {
  @apply block;
}
@screen xl {
  .homepage .header-logo a.header-logo-desktop {
    @apply block;
  }
  .homepage .header-logo a.header-logo-mobile {
    @apply hidden;
  }
}

header {
  background-color: var(--background);
  @apply flex items-center fixed top-0 left-0 w-full h-20 lg:h-25 z-1000;
}
header.active {
  @apply bg-white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.08);
}
header.active .header-logo a.header-logo-desktop {
  @apply hidden;
}
header.active .header-logo a.header-logo-mobile {
  @apply block;
}
header.active .menu > li.active a, header.active .menu > li:hover a {
  @apply text-red-500;
}
header.active .menu > li.active a::before, header.active .menu > li:hover a::before {
  @apply bg-red-500;
}
header.active .menu > li.has-child {
  @apply text-blue-500;
}
header.active .menu > li.has-child em {
  @apply text-blue-500;
}
header.active .menu > li.has-child:hover em {
  @apply text-red-500;
}
header.active .menu a {
  @apply text-blue-500;
}
header.active .header-language ul li.active a, header.active .header-language ul li:hover a {
  @apply text-red-500;
}
header.active .header-language ul li.active a::before, header.active .header-language ul li:hover a::before {
  @apply bg-red-500;
}
header.active .header-language ul li::after {
  @apply bg-stroke-CC;
}
header.active .header-language ul li a {
  @apply relative text-blue-500;
}
header.active .header-menu-search em {
  @apply text-blue-500;
}
header .header-wrapper {
  @apply px-10 md:px-25 w-full flex items-center justify-between;
}
header .header-logo {
  @apply max-h-[calc(75/1920*100rem)] h-full flex-shrink-0 basis-[calc(220/1920*100rem)] max-w-[calc(220/1920*100rem)] w-full;
}
header .header-logo a {
  @apply block h-full;
}
header .header-logo a img {
  @apply h-full w-full object-contain;
}
header .header-logo a.header-logo-desktop {
  @apply hidden;
}
header .header-logo a.header-logo-mobile {
  @apply block;
}
header .header-hambuger {
  @apply flex-shrink-0 basis-[calc(40/1920*100rem)] max-w-[calc(40/1920*100rem)] w-full flex flex-col gap-1 mt-auto mb-auto xl:hidden;
}
header .header-hambuger.active span:first-child {
  transform: translateY(10px) rotate(45deg);
}
header .header-hambuger.active span:nth-child(2) {
  transform: scaleX(0);
}
header .header-hambuger.active span:last-child {
  transform: translateY(-7px) rotate(-45deg);
}
header .header-hambuger span {
  @apply bg-blue-500 pointer-events-none block w-full h-[5px] transition-all duration-300 rounded-3xl;
}
header .header-content {
  @apply flex items-center justify-between xl:w-full xl:flex-shrink-0 xl:basis-[calc(1388/1920*100rem)] xl:max-w-[calc(1388/1920*100rem)];
}
header .header-menu {
  flex: 1;
  @apply w-full max-w-full;
}
@media (max-width: 1279.98px) {
  header .header-menu {
    @apply hidden;
  }
}
header .menu {
  @apply flex items-center gap-14;
}
header .menu > li {
  @apply relative inline-flex items-center;
}
header .menu > li:first-child a::after {
  content: "\f015";
  @apply font-awesome font-light;
}
header .menu > li:first-child span {
  @apply text-0;
}
header .menu > li.active > a, header .menu > li:hover > a {
  color: var(--menu-active);
}
header .menu > li.active > a::before, header .menu > li:hover > a::before {
  background-color: var(--menu-active);
}
header .menu > li.active.has-child em, header .menu > li:hover.has-child em {
  color: var(--menu-active);
}
header .menu > li.has-child em {
  color: var(--menu);
  @apply text-xl pl-4;
}
header .menu > li.has-child:hover em {
  color: var(--menu-active);
}
header .menu > li.has-child:hover .drop-down {
  @apply opacity-100 visible;
}
header .menu a {
  color: var(--menu);
  @apply relative inline-flex whitespace-nowrap global-text-20 font-medium uppercase;
  @apply transition-all duration-300;
}
header .menu a::before {
  content: "";
  top: calc(100% + 0.3125rem);
  @apply absolute left-0 bg-transparent w-full h-[calc(1/1920*100rem)];
  @apply transition-all duration-300;
}
header .menu .drop-down {
  @apply absolute left-0 top-full opacity-0 invisible;
}
header .menu .drop-down ul {
  @apply py-5;
}
header .menu .drop-down li {
  @apply px-5;
}
header .menu .drop-down li + li {
  @apply mt-4;
}
header .menu .drop-down li.active > a span {
  @apply border-b border-white opacity-100;
}
header .menu .drop-down li a {
  @apply inline-flex;
}
header .menu .drop-down li a:hover span {
  @apply opacity-100;
}
header .menu .drop-down li span {
  @apply opacity-75 text-white;
}
header .menu .box-parent-category {
  @apply relative h-[calc(244/1920*100rem)] bg-red-500;
}
header .menu .box-parent-category li:hover .sub-menu-product {
  @apply opacity-100 visible;
}
header .menu .sub-menu-product {
  @apply absolute top-0 left-full bg-red-500 border-l border-red-400 opacity-0 invisible h-[calc(244/1920*100rem)] overflow-auto;
}
header .header-language {
  @apply relative;
}
header .header-language ul {
  @apply flex items-center;
}
header .header-language ul li {
  @apply relative pl-[calc(13.5/1920*100rem)] ml-[calc(13.5/1920*100rem)];
}
header .header-language ul li.active a, header .header-language ul li:hover a {
  color: var(--menu-active);
}
header .header-language ul li.active a::before, header .header-language ul li:hover a::before {
  background-color: var(--menu-active);
}
header .header-language ul li::after {
  content: "";
  background-color: var(--border);
  @apply absolute left-0 top-1/2 -translate-y-1/2 w-[calc(2/1920*100rem)] h-5;
}
header .header-language ul li a {
  color: var(--menu);
  @apply relative uppercase global-text-20;
  @apply transition-all duration-300;
}
header .header-language ul li a::before {
  content: "";
  top: calc(100% + 0.3125rem);
  @apply absolute left-0 bg-transparent w-full h-[calc(1/1920*100rem)];
  @apply transition-all duration-300;
}
@media (max-width: 1279.98px) {
  header .header-language {
    @apply hidden;
  }
}
header .header-menu-search em {
  color: var(--menu);
  @apply text-xl cursor-pointer;
}

.header-menu-mobile {
  @apply fixed top-20 lg:top-25 left-0 h-screen z-50 bg-secondary-2 w-full  xl:hidden transition-all duration-500 -translate-x-full z-100;
}
.header-menu-mobile.active {
  @apply translate-x-0;
}
.header-menu-mobile .header-language {
  @apply block;
}
.header-menu-mobile .header-language ul {
  @apply flex gap-5 pl-4;
}
.header-menu-mobile .header-language li.active a {
  @apply text-red-500;
}
.header-menu-mobile .header-language a {
  @apply text-blue-500 py-5 inline-flex uppercase;
}
.header-menu-mobile .header-menu {
  @apply block;
}
.header-menu-mobile .header-menu > ul li {
  @apply relative;
}
.header-menu-mobile .header-menu > ul li.active > a {
  @apply font-bold bg-blue-500 text-secondary-2;
}
.header-menu-mobile .header-menu > ul > li {
  @apply border-b border-blue-500/60 relative first:border-t-0;
}
.header-menu-mobile .header-menu > ul > li > a {
  @apply flex items-center  px-4 h-[45px] justify-between text-blue-500;
}
.header-menu-mobile .header-menu > ul > li.has-child > em {
  @apply hidden;
}
.header-menu-mobile .header-menu > ul > li.has-child .drop-down {
  @apply hidden;
}
.header-menu-mobile .header-menu > ul > li .dropdown-button {
  @apply absolute top-0 right-0 px-4 flex-center h-[42px];
}
.header-menu-mobile .header-menu > ul > li .dropdown-button em {
  @apply transition-all duration-300;
}
.header-menu-mobile .header-menu > ul > li .dropdown-button.active em {
  @apply rotate-90;
}
.header-menu-mobile .header-menu > ul > li .box-parent-category > ul > li > a {
  @apply font-bold;
}
.header-menu-mobile .header-menu > ul > li ul > li:last-child > a {
  @apply border-b-0;
}
.header-menu-mobile .header-menu > ul > li ul > li > ul > li > a {
  padding-left: 40px;
}
.header-menu-mobile .header-menu > ul > li ul li a {
  @apply pr-4 pl-10 py-4 flex items-center border-b border-white/30 bg-white;
}
.header-menu-mobile .header-menu > ul > li ul li ul li a {
  @apply pl-15;
}

.home-banner .swiper-slide .video-wrapper {
  @apply h-screen;
}
.home-banner .swiper-slide .video-wrapper video {
  @apply w-full h-full object-cover;
}
.home-banner .play-icon {
  @apply text-white w-20 h-20 text-xl rounded-full flex-center bg-blue-500;
}
.home-banner .play-icon.is-playing {
  @apply hidden;
}
.home-banner::before {
  content: "";
  background-image: url("../img/icon/before_homebanner.svg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @apply absolute bottom-0 left-0 w-full z-20 h-[38px] translate-y-5;
}
@screen md {
  .home-banner::before {
    @apply h-[calc(170/1920*100rem)] translate-y-[calc(128/1920*100rem)] z-10;
  }
}
.home-banner .item {
  @apply relative z-1;
}
.home-banner .item::before, .home-banner .item::after {
  content: "";
  @apply w-full z-10 absolute left-0;
}
.home-banner .item::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
  @apply top-0 h-[calc(170/1920*100rem)];
}
.home-banner .item::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  @apply bottom-0 h-[calc(290/1920*100rem)];
}
.home-banner .box-content {
  @apply absolute bottom-[20%] left-1/2 -translate-x-1/2 z-20 w-full text-center;
  @apply md:bottom-[15%] w-full xl:bottom-[calc(160/1920*100rem)];
}
.home-banner .box-content p {
  @apply text-white global-text-24 md:global-text-40 xl:global-text-48;
}
.home-banner .box-content p span {
  @apply inline-block mx-3 xl:mx-5 font-bold global-text-24 md:global-text-40 xl:text-64 xl:leading-[calc(64/56)];
}
.home-banner .swiper-pagination {
  @apply pointer-events-none;
}
.home-banner .swiper-pagination .swiper-pagination-bullet {
  @apply pointer-events-auto;
}
@media (max-width: 1279.98px) {
  .home-banner .container {
    @apply max-w-full px-3.75 !important;
  }
}

.page-banner .box-content {
  @apply relative;
}
.page-banner .box-content::after {
  content: "";
  background-image: url("../img/about/7.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @apply absolute left-0 -bottom-[calc(63/1920*100rem)] w-full h-25;
}
.page-banner .box-content img, .page-banner .box-content video, .page-banner .box-content iframe {
  @apply w-full;
}
.page-banner .box-content iframe {
  height: 31.25rem;
}

@screen lg {
  .about-1.section-large {
    @apply pb-[calc(200/1920*100rem)];
  }
}
.about-1 .content {
  @apply pl-20 mt-6 relative max-w-[calc(608/1920*100rem)];
}
.about-1 .content::before, .about-1 .content::after {
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @apply absolute w-15 h-15;
}
.about-1 .content::before {
  background-image: url("../img/about/1.png");
  @apply top-0 left-0;
}
.about-1 .content::after {
  background-image: url("../img/about/2.png");
  @apply bottom-0 right-0;
}
.about-1 .desc {
  @apply global-text-24 text-[#151515];
}
.about-1 .desc p {
  @apply relative pb-5 mb-5;
}
.about-1 .desc p::after {
  content: "";
  @apply bg-red-500 w-20 h-[calc(2/1920*100rem)];
  @apply absolute left-0 bottom-0;
}
.about-1 .name {
  @apply global-text-24 font-semibold text-blue-500;
}
.about-1 .name span {
  @apply text-body-text-66 font-normal block mb-1;
}
.about-1 .img-src img {
  mix-blend-mode: luminosity;
  @apply object-contain opacity-45 !important;
}
.about-1 .avatar {
  @apply absolute top-6 right-0 w-full sm:w-[calc(496/1920*100rem)] h-full sm:h-[calc(593/1920*100rem)];
}
@screen md {
  .about-1 .avatar::after {
    content: "";
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFF 100%);
    @apply absolute -bottom-1 left-0 w-full h-[calc(179/1920*100rem)];
  }
}
@media (max-width: 1023.98px) {
  .about-1 .avatar {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
.about-1 .avatar img {
  @apply h-full;
}

@media (max-width: 767.98px) {
  .about-2 {
    @apply mb-10;
  }
}
.about-2 .swiper-thumb::before {
  content: "";
  @apply absolute top-[21%] left-1/2 -translate-x-1/2;
  @apply bg-blue-100 w-full h-[calc(2/1920*100rem)];
}
.about-2 .swiper-thumb .icon {
  @apply h-8 w-8 rounded-full border-blue-500 relative bg-white;
  @apply border cursor-pointer scale-[77%] mx-auto;
  @apply transition-all duration-300;
}
.about-2 .swiper-thumb .icon::before {
  content: "";
  @apply h-2 w-2 rounded-full bg-blue-500 scale-[60%];
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  @apply transition-all duration-300;
}
.about-2 .swiper-thumb .year {
  @apply global-text-20 font-medium text-blue-400 text-center mt-2.25;
}
.about-2 .swiper-thumb .swiper-slide-thumb-active .icon {
  @apply scale-100 border-red-500;
}
.about-2 .swiper-thumb .swiper-slide-thumb-active .icon::before {
  @apply bg-red-500;
}
.about-2 .swiper-thumb .swiper-slide-thumb-active .year {
  @apply text-red-500;
}
.about-2 .swiper-main {
  @apply pb-5 -m-8;
}
.about-2 .swiper-main .wrapper {
  @apply overflow-hidden p-8;
}
.about-2 .swiper-main .swiper-slide {
  @apply transition-all duration-300 opacity-40;
}
@screen xl {
  .about-2 .swiper-main .swiper-slide:hover {
    @apply opacity-100 scale-115;
  }
}
.about-2 .swiper-main h3 {
  @apply text-center text-red-500 global-text-32 font-semibold mt-5;
}
.about-2 .swiper-main .desc {
  @apply global-text-20 text-[#151515] text-center;
}
.about-2 .swiper-main .img-src img {
  @apply rounded-1;
}
@screen md {
  .about-2 .swiper-main .swiper {
    @apply overflow-visible ml-0;
  }
}
@screen xl {
  .about-2 .swiper-main {
    @apply pb-0;
  }
}
@media (max-width: 1279.98px) {
  .about-2 .swiper-main .swiper-slide-active {
    @apply opacity-100;
  }
}

.about-3 .item + .item {
  @apply mt-5;
}
.about-3 .box-img, .about-3 .box-content {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.about-3 .box-img img {
  @apply rounded-1;
}
.about-3 .box-content {
  @apply rounded-1 flex items-center bg-blue-500;
}
.about-3 .box-content h3 {
  @apply global-text-48 font-bold;
}
.about-3 .box-content .desc {
  @apply global-text-20 mt-6;
}
@screen lg {
  .about-3 .box-content .desc {
    max-height: 8.75rem;
    @apply overflow-y-auto pr-2;
  }
  .about-3 .box-content .desc::-webkit-scrollbar {
    border-radius: 0.4166666667rem;
    width: 0.4166666667rem;
    @apply bg-white;
  }
  .about-3 .box-content .desc::-webkit-scrollbar-thumb {
    border-radius: 0.4166666667rem;
    outline: 1px solid white;
    @apply bg-blue-500;
  }
}
@screen lg {
  .about-3 .box-img {
    flex: 0 0 calc(50% - 0.5208333333rem);
    max-width: calc(50% - 0.5208333333rem);
  }
  .about-3 .box-content {
    flex: 0 0 calc(50% - 0.5208333333rem);
    max-width: calc(50% - 0.5208333333rem);
  }
  .about-3 .item:nth-child(odd) .box-img {
    @apply order-1;
  }
  .about-3 .item:nth-child(odd) .box-content {
    @apply order-2;
  }
  .about-3 .item:nth-child(even) .box-img {
    @apply order-2;
  }
  .about-3 .item:nth-child(even) .box-content {
    @apply order-1 bg-blue-600;
  }
}

.about-4 {
  @apply relative;
}
@media (max-width: 767.98px) {
  .about-4 h2 {
    @apply mb-10;
  }
}
.about-4 .wrapper {
  @apply py-15 relative;
}
.about-4 .wrapper::before {
  background: linear-gradient(180deg, rgba(232, 248, 255, 0) 0%, #E8F8FF 50%, rgba(238, 221, 232, 0) 100%, rgba(232, 248, 255, 0) 100%);
  content: "";
  @apply absolute inset-0;
}
@screen lg {
  .about-4 .wrapper {
    transform: translateY(-0.78125rem);
    @apply py-[calc(120/1920*100rem)];
  }
}
.about-4 .tabslet-tab {
  @apply relative z-20;
}
.about-4 .tabslet-tab li {
  @apply flex items-center md:items-start;
}
.about-4 .tabslet-tab li + li {
  @apply mt-10 lg:mt-20;
}
.about-4 .tabslet-tab a, .about-4 .tabslet-tab .content {
  @apply w-full;
}
.about-4 .tabslet-tab a {
  flex: 0 0 25%;
  max-width: 25%;
}
.about-4 .tabslet-tab a:hover .img-src img {
  @apply border-red-500;
}
.about-4 .tabslet-tab a:hover + .content p {
  @apply text-red-500;
}
.about-4 .tabslet-tab a .img-src img {
  @apply border-[calc(4/1920*100rem)] border-white rounded-full;
}
@screen md {
  .about-4 .tabslet-tab a {
    flex: 0 0 38.4615384615%;
    max-width: 38.4615384615%;
  }
}
.about-4 .tabslet-tab .content {
  flex: 0 0 75%;
  max-width: 75%;
}
.about-4 .tabslet-tab .content .icon {
  @apply h-[calc(48/1920*100rem)] mb-3;
}
.about-4 .tabslet-tab .content .icon img {
  @apply h-full object-contain;
}
.about-4 .tabslet-tab .content p {
  @apply text-blue-500 text-28 font-medium leading-1.25 relative uppercase;
}
.about-4 .tabslet-tab .content p::after {
  content: "";
  top: calc(100% + 0.625rem);
  @apply absolute h-[calc(1/1920*100rem)] w-10 bg-red-500 left-0;
}
@screen md {
  .about-4 .tabslet-tab .content {
    flex: 0 0 61.5384615385%;
    max-width: 61.5384615385%;
  }
}
.about-4 .tabslet-tab.tab-left {
  @apply mt-20;
}
@screen md {
  .about-4 .tabslet-tab.tab-left {
    @apply mt-0;
  }
  .about-4 .tabslet-tab.tab-left a {
    @apply order-2;
  }
  .about-4 .tabslet-tab.tab-left .content {
    @apply order-1 text-right;
  }
  .about-4 .tabslet-tab.tab-left .content p::after {
    left: unset;
    @apply right-0;
  }
  .about-4 .tabslet-tab.tab-right a {
    @apply order-1;
  }
  .about-4 .tabslet-tab.tab-right .content {
    @apply order-2 text-left;
  }
}
@screen lg {
  .about-4 .tabslet-tab.tab-left li {
    @apply translate-x-[15%];
  }
  .about-4 .tabslet-tab.tab-right li {
    @apply -translate-x-[15%];
  }
}
.about-4 .col.col-center {
  @apply flex-center;
}
.about-4 .tabslet-content h3 {
  @apply global-text-40 text-red-500 font-medium mb-3;
}
.about-4 .tabslet-content .desc {
  @apply global-text-24 text-[#151515];
}
.about-4 .item {
  @apply relative pt-[70%] md:pt-full w-full;
}
.about-4 .item .bg-img {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  @apply w-full sm:w-[calc(420/1920*100rem)] md:w-[calc(584/1920*100rem)] h-[calc(420/1920*100rem)] md:h-[calc(584/1920*100rem)];
}
.about-4 .item .bg-img > img {
  mix-blend-mode: overlay;
}
.about-4 .item .bg-img::before {
  content: "";
  background: linear-gradient(180deg, rgba(228, 242, 255, 0.2) 0%, #DBF6FF 25%, #C8EBFF 50%, rgba(228, 242, 255, 0.2) 82.29%, rgba(228, 242, 255, 0) 100%);
  filter: blur(20px);
  @apply absolute w-full sm:w-[calc(672/1920*100rem)] h-[calc(672/1920*100rem)] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}
@media (max-width: 767.98px) {
  .about-4 .item .bg-img::before {
    @apply h-[calc(520/1920*100rem)];
  }
}
.about-4 .item .content-innert {
  box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.1);
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex-center;
  @apply w-[calc(260/1920*100rem)] md:w-[calc(344/1920*100rem)] h-[calc(260/1920*100rem)] md:h-[calc(344/1920*100rem)] bg-white rounded-full;
}
.about-4 .item .img-src {
  @apply h-20 md:h-[calc(97/1920*100rem)];
}
.about-4 .item .img-src img {
  @apply h-full object-contain;
}
.about-4 .tabslet-wrapper {
  @apply text-center mt-10 lg:mt-0;
}
@screen lg {
  .about-4 .tabslet-wrapper {
    transform: translateY(1.3020833333rem);
  }
}
@media (max-width: 1023.98px) {
  .about-4 .about-4-top .col:nth-child(1) {
    @apply order-2;
  }
  .about-4 .about-4-top .col:nth-child(2) {
    @apply order-1 mb-10;
  }
  .about-4 .about-4-top .col:nth-child(3) {
    @apply order-3;
  }
}
@media (max-width: 767.98px) {
  .about-4 .about-4-top .col:nth-child(3) {
    @apply mt-10;
  }
}
.about-4 .mobile-tabslet-group {
  @apply relative;
}
.about-4 .mobile-tabslet-group .tabslet-content {
  @apply mt-5;
}
.about-4 .mobile-tabslet-group + .mobile-tabslet-group {
  @apply mt-10;
}

@screen xl {
  .about-6.section-medium {
    @apply pt-[calc(116/1920*100rem)];
  }
}
.about-6::before {
  content: "";
  background-image: url("../img/about/8.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @apply absolute left-0 -top-10 w-full h-[calc(1483/1920*100rem)] z-10;
}
.about-6 .box-content h3 {
  @apply text-28 font-medium text-blue-500 mt-5;
}
.about-6 .box-content ul {
  @apply mt-3;
}
.about-6 .box-content li {
  @apply text-black text-lg leading-1.33333 py-1.5 bg-white pl-5.5;
  @apply border-l-2 border-red-500;
}
.about-6 .box-content li + li {
  @apply mt-2;
}
@media (max-width: 1279.98px) {
  .about-6-swiper {
    @apply pb-5;
  }
}

.home-1 {
  @apply relative z-10;
}
.home-1-swiper {
  @apply -mx-2.5;
}
.home-1-swiper .swiper {
  @apply px-2.5;
}
.home-1-swiper .swiper-slide {
  @apply h-auto pt-12;
}
.home-1-swiper .item {
  @apply h-full;
}
.home-1-swiper .item .box-img {
  background: none !important;
  height: 7.9166666667rem;
  padding: 0;
}
.home-1-swiper .item .box-img img {
  @apply w-full h-full rounded-none;
}
@media (max-width: 1279.98px) {
  .home-1-swiper {
    @apply pb-5;
  }
}
.home-1 .item-product-cate:hover, .home-1 .item-product-cate .active {
  box-shadow: 3px 3px 8px -2px rgb(0, 0, 0);
  @apply -translate-y-5;
}

@media (max-width: 767.98px) {
  .home-2 .font-secondary {
    font-size: 40px;
  }
}
.home-2 .home-2-bottom {
  @apply mt-6 grid gap-7.5;
}
@screen lg {
  .home-2 .home-2-bottom {
    @apply gap-y-0;
    grid-template-rows: 9.5833333333rem repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
  }
}
.home-2 .grid-item:nth-child(2) img {
  border-radius: 0.4166666667rem 0.4166666667rem 0 0;
}
.home-2 .grid-item:nth-child(2) .content {
  border-radius: 0 0 0.4166666667rem 0.4166666667rem;
  @apply bg-blue-700 px-5 pt-7.5 pb-6;
}
.home-2 .grid-item:nth-child(3) img {
  border-radius: 0 0 0.4166666667rem 0.4166666667rem;
}
.home-2 .grid-item:nth-child(5) .img-src > img {
  @apply rounded-[calc(8/1920*100rem)];
}
@screen lg {
  .home-2 .grid-item:nth-child(1) {
    grid-area: 1/1/2/2;
    @apply pb-8;
  }
  .home-2 .grid-item:nth-child(2) {
    grid-area: 2/1/4/2;
    @apply pb-18;
  }
  .home-2 .grid-item:nth-child(3) {
    grid-area: 1/2/4/3;
  }
  .home-2 .grid-item:nth-child(4) {
    grid-area: 1/3/2/4;
    @apply pb-8;
  }
  .home-2 .grid-item:nth-child(5) {
    grid-area: 2/3/4/4;
    @apply pb-18 relative;
  }
}
.home-2 .grid-item .item > .number {
  @apply global-text-40 font-medium text-blue-500;
}
.home-2 .grid-item .item > .number > span:not(:first-child) {
  @apply inline-block ml-2;
}
.home-2 .grid-item .item > p {
  @apply mt-1.5 global-text-24 text-[#050505];
}
.home-2 .grid-item .item + .item {
  @apply mt-[calc(23/1920*100rem)];
}
.home-2 .grid-item .video-popup .btn-play {
  background: linear-gradient(129deg, #275394 0%, #2979B6 51.56%, #2B98D1 100%);
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer;
  @apply w-20 h-20 rounded-full flex-center text-white text-32;
}
.home-2 .grid-item .video-popup .btn-play:hover em {
  @apply text-red-500;
}
.home-2 .grid-item .video-popup .btn-play em {
  @apply transition-all;
}
.home-2 .content .icon {
  @apply flex text-white items-end gap-3 md:gap-8;
}
.home-2 .content .icon em {
  @apply text-32 md:text-[calc(48/1920*100rem)] leading-1.2;
}
.home-2 .content .icon .number {
  @apply text-28 md:text-4xl font-medium leading-1.2;
}
.home-2 .content > p {
  @apply text-white global-text-24 mt-1.5;
}

.home-3 .desc {
  @apply global-text-24 text-black;
}
.home-3 .box-img > img {
  @apply object-contain !important;
}
@media (max-width: 1023.98px) {
  .home-3 .box-img {
    @apply pt-[80%];
  }
}
.home-3 .btn-wrap {
  @apply my-5;
}
.home-3-swiper img {
  @apply rounded-[calc(16/1920*100rem)];
}
.home-3-swiper .count-index {
  @apply flex gap-20;
}
.home-3-swiper .count-index span {
  @apply global-text-24 font-medium;
}
.home-3-swiper .count-index span.number-index {
  @apply text-red-500;
}
.home-3-swiper .count-index span.total-index {
  @apply text-blue-500 relative;
}
.home-3-swiper .count-index span.total-index::before {
  content: "";
  right: 1.3020833333rem;
  width: 2.5520833333rem;
  height: 0.0520833333rem;
  @apply bg-blue-200;
  @apply absolute top-1/2 -translate-y-1/2;
}
@media (max-width: 1023.98px) {
  .home-3 .row .col + .col {
    @apply mt-7.5;
  }
}

.home-5.section-medium {
  @apply pt-0;
}
@media (max-width: 1279.98px) {
  .home-5-swiper {
    @apply pb-5;
  }
}
.home-5 .desc p {
  @apply global-text-24 text-black;
}
.home-5 .desc p.high-light {
  @apply text-red-500 py-6;
}
.home-5 .font-secondary {
  @apply mb-2;
}
@media (max-width: 767.98px) {
  .home-5 .font-secondary {
    font-size: 40px;
  }
}
.home-5 .img-src {
  @apply xl:scale-110;
}
.home-5 .img-src img {
  @apply w-full;
}
@media (max-width: 1023.98px) {
  .home-5 .row .col + .col {
    @apply mt-7.5;
  }
}

.about-page .home-5 .desc p {
  @apply text-white;
}

.item.news-item:hover .content h3 {
  @apply text-red-500;
}
.item.news-item .img-src a {
  @apply rounded-[calc(8/1920*100rem)];
}
.item.news-item .content time {
  @apply text-body-text-99 leading-1.5 my-3 inline-block;
}
.item.news-item .content time::before {
  content: "\f017";
  @apply font-awesome text-lg leading-1.25 inline-block mr-2;
}
.item.news-item .content h3 {
  @apply global-text-24 font-medium text-black line-clamp-3;
  @apply transition-all duration-300;
}

@media (max-width: 1279.98px) {
  .home-4-swiper {
    @apply pb-5;
  }
}
.home-4 .desc {
  @apply text-center my-6 global-text-24 text-black;
}
.home-4 .img-src {
  height: 7.1354166667rem;
  text-align: center;
}
.home-4 .img-src img {
  @apply h-full object-contain;
}

.line-svg {
  @apply relative z-10;
}
.line-svg svg {
  @apply w-full;
}

@screen xl {
  .home-6.section-large {
    @apply pb-[calc(200/1920*100rem)];
  }
}
.home-6::before {
  content: "";
  background-image: url(../img/home/6.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 8.3333333333rem;
  @apply absolute -top-5 left-0 w-full;
}
.home-6 .desc {
  @apply mt-6 global-text-20 text-white max-w-[calc(1056/1920*100rem)] mx-auto text-center;
}

.product-detail.section-medium {
  @apply pb-0;
}
.product-detail .box-left {
  @apply flex justify-between flex-wrap lg:flex-nowrap;
}
.product-detail .desc {
  @apply global-text-20 text-black;
}
.product-detail .swiper-thumb, .product-detail .swiper-main {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.product-detail .swiper-thumb {
  @apply order-2 mt-5 lg:mt-0;
}
.product-detail .swiper-thumb .btn-prev {
  @apply absolute top-1/2 left-0;
}
.product-detail .swiper-thumb .btn-prev::before {
  content: "\f077";
  @apply text-blue-500 font-light text-2xl md:text-40 leading-none;
}
.product-detail .swiper-thumb .btn-next {
  @apply absolute top-1/2 right-0;
}
.product-detail .swiper-thumb .btn-next::before {
  content: "\f078";
  @apply text-blue-500 font-light text-2xl md:text-40 leading-none;
}
@media (max-width: 1023.98px) {
  .product-detail .swiper-thumb {
    @apply px-14;
  }
  .product-detail .swiper-thumb .btn-prev, .product-detail .swiper-thumb .btn-next {
    @apply -translate-y-1/2;
  }
  .product-detail .swiper-thumb .btn-prev::before, .product-detail .swiper-thumb .btn-next::before {
    @apply -rotate-90;
  }
}
@media (max-width: 767.98px) {
  .product-detail .swiper-thumb {
    @apply px-7.5;
  }
}
@screen lg {
  .product-detail .swiper-thumb {
    flex: 0 0 11.8055555556%;
    max-width: 11.8055555556%;
    @apply order-1 py-14;
  }
  .product-detail .swiper-thumb .swiper {
    height: 21.1458333333rem;
  }
  .product-detail .swiper-thumb .btn-prev {
    @apply top-0 left-1/2 -translate-x-1/2;
  }
  .product-detail .swiper-thumb .btn-next {
    top: unset;
    @apply bottom-0 left-1/2 -translate-x-1/2;
  }
  .product-detail .swiper-main {
    flex: 0 0 87.037037037%;
    max-width: 87.037037037%;
    @apply order-2;
  }
}

.product-info .tabslet-tab {
  @apply flex gap-5;
}
.product-info .tabslet-tab li.active a {
  @apply bg-red-500 border-red-500 text-white;
}
.product-info .tabslet-tab a {
  @apply inline-flex items-center justify-center px-2.5 py-2 global-text-20 text-blue-500;
  @apply bg-transparent border border-blue-200 rounded-1;
  @apply transition-all duration-300;
}
.product-info .tabslet-tab a:hover {
  @apply bg-red-500 border-red-500 text-white;
}
.product-info .tabslet-content {
  @apply mt-5;
}
.product-info .tabslet-content h3 {
  @apply text-blue-500 font-semibold global-text-24 mb-5;
}
.product-info .tabslet-content p {
  @apply global-text-20;
}
.product-info .tabslet-content p + p {
  @apply mt-2;
}

.productpage .product-form {
  @apply hidden;
}

.product-form .form-wrap input:not([type=submit]), .product-form .form-wrap textarea {
  @apply w-full resize-none rounded-1;
}
.product-form .form-wrap input:not([type=submit])::placeholder, .product-form .form-wrap textarea::placeholder {
  @apply text-body-text-66 text-lg leading-1.33333;
}
.product-form .form-wrap input:not([type=submit]) {
  @apply px-5 py-3 text-body-text-66 text-lg leading-1.33333;
}
.product-form .form-wrap input[type=submit] {
  @apply text-0 absolute cursor-pointer inset-0 !important;
}
.product-form .form-wrap textarea {
  @apply mt-5 h-[calc(112/1920*100rem)] pt-3 pl-5;
}
.product-form .form-wrap .frm-btn {
  @apply bg-red-500 mt-5 inline-flex items-center gap-3 px-15 h-11 rounded-[calc(8/1920*100rem)];
}
.product-form .form-wrap .frm-btn::before {
  content: "Send";
  @apply text-xl font-normal text-white leading-[28/20];
}
.product-form .form-wrap .frm-btn::after {
  content: "\f178";
  @apply font-awesome text-xl leading-none text-white font-light;
}

@media (max-width: 1279.98px) {
  .product-other-swiper {
    @apply pb-5;
  }
}

.product-list .box-top.section-medium {
  @apply pb-0;
}
.product-list .box-top-swiper {
  @apply -mx-2.5;
}
.product-list .box-top-swiper .swiper {
  @apply px-2.5;
}
.product-list .box-top-swiper .btn-prev, .product-list .box-top-swiper .btn-next {
  top: 24%;
  @apply -translate-y-0;
}
@media (max-width: 1279.98px) {
  .product-list .box-top-swiper {
    @apply pb-5;
  }
}
.product-list .box-top .desc {
  @apply global-text-24 text-[#151515] mt-8;
}
.product-list .box-top .item-product-cate {
  @apply pt-8;
}
.product-list .box-top .item-product-cate:hover .box-img, .product-list .box-top .item-product-cate.active .box-img {
  box-shadow: 3px 3px 8px -2px rgb(0, 0, 0);
  @apply -translate-y-5;
}
.product-list .box-bottom {
  @apply overflow-hidden;
}
.product-list .box-bottom ul {
  @apply flex items-center justify-center gap-5 my-5;
}
.product-list .box-bottom ul li.active a {
  @apply bg-red-500 text-white border-red-500;
}
.product-list .box-bottom ul a {
  @apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20;
  @apply inline-flex items-center justify-center py-2 px-2.5;
  @apply transition-all duration-300;
}
.product-list .box-bottom ul a:hover {
  @apply bg-red-500 text-white border-red-500;
}
.product-list .box-bottom .desc {
  @apply global-text-24 text-[#151515] mb-5;
}

.item-product-cate {
  @apply transition-all duration-300;
}
.item-product-cate:hover .content h3, .item-product-cate.active .content h3 {
  @apply text-red-500;
}
.item-product-cate:hover .content h3::after, .item-product-cate.active .content h3::after {
  @apply opacity-100;
}
.item-product-cate .box-img {
  border-radius: 0.4166666667rem;
  background: rgba(204, 204, 204, 0.5);
  padding: 0.2604166667rem 0.78125rem;
  @apply text-center transition-all duration-300;
}
.item-product-cate .box-img img {
  border-radius: 0.4166666667rem;
  @apply transition-all duration-300;
}
.item-product-cate .content {
  @apply mt-5 text-center;
}
.item-product-cate .content h3 {
  @apply text-28 leading-1.33333 font-medium flex flex-col gap-1 capitalize;
  @apply transition-all duration-300;
}
.item-product-cate .content h3::after {
  content: "\f0d7";
  @apply font-black text-2xl leading-1.33333 font-awesome text-red-500;
  @apply transition-all duration-300 opacity-0;
}

.item-product:hover h3 {
  @apply text-red-500;
}
.item-product .box-content {
  @apply mt-3.75;
}
.item-product h3 {
  @apply global-text-24 text-black font-medium capitalize;
  @apply transition-all duration-300;
}

.news-1 {
  background: #f7fafe;
  display: flex;
}
.news-1::before {
  content: "";
  background-image: url("../img/news/line.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @apply absolute left-0 -bottom-5 w-full h-40;
}
.news-1 .heading {
  font-family: League Spartan;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.1666666667;
  @apply uppercase;
}
.news-1-swiper .swiper-container {
  position: relative;
  width: 70%;
  margin: 0 auto;
}
.news-1-swiper .swiper-button-next, .news-1-swiper .swiper-button-prev {
  color: #08518F;
  border: 1px solid #9CB9D2;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
@media (max-width: 1279.98px) {
  .news-1-swiper {
    @apply pb-5;
  }
}

.news-item:hover h4 {
  @apply text-red-500;
}
.news-item .news-slide-date {
  @apply flex my-5 text-body-text-99 gap-2 text-xl;
}
.news-item .news-slide-date p {
  @apply leading-1.25;
}
.news-item h4 {
  @apply text-28 leading-1.25 text-black font-medium line-clamp-3;
  @apply transition-all duration-300;
}

.news-2 ul {
  @apply flex items-center justify-center gap-5 mb-5;
}
.news-2 ul li.active a {
  @apply bg-red-500 text-white border-red-500;
}
.news-2 ul a {
  @apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20;
  @apply inline-flex items-center justify-center py-2 px-2.5;
  @apply transition-all duration-300;
}
.news-2 ul a:hover {
  @apply bg-red-500 text-white border-red-500;
}

.showListNews:hover h4 {
  @apply text-red-500;
}
.showListNews .news-slide-date {
  @apply flex my-3 text-body-text-99 gap-2;
}
.showListNews .news-slide-date p {
  @apply leading-1.25;
}
.showListNews h4 {
  @apply global-text-24 text-black font-medium line-clamp-3;
  @apply transition-all duration-300;
}

.recruitment-detail {
  background-position: 223px 0;
}
.recruitment-detail .info {
  @apply flex flex-wrap lg:flex-nowrap gap-y-5 justify-between p-8 bg-[#F1F6FD] rounded-1;
}
.recruitment-detail .info .box-img, .recruitment-detail .info .box-content {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.recruitment-detail .info .box-img img {
  @apply rounded-1;
}
.recruitment-detail .info .box-content table {
  @apply w-full;
}
.recruitment-detail .info .box-content td {
  @apply global-text-20 text-black py-2.5;
}
.recruitment-detail .info .box-content td:first-child {
  @apply font-semibold;
}
.recruitment-detail .info .box-content tr {
  @apply border-t border-[#e1e1e1];
}
.recruitment-detail .info .box-content tr:last-child {
  @apply border-b border-[#e1e1e1];
}
.recruitment-detail .info .box-content tr:last-child td {
  @apply text-red-500;
}
@screen lg {
  .recruitment-detail .info .box-img {
    flex: 0 0 42.4342105263%;
    max-width: 42.4342105263%;
  }
  .recruitment-detail .info .box-content {
    flex: 0 0 54.1666666667%;
    max-width: 54.1666666667%;
  }
}
.recruitment-detail .attributes .item {
  @apply mt-8 p-8 bg-[#F1F6FD]  rounded-1;
}
.recruitment-detail .attributes .item ul.describe li {
  @apply text-black global-text-20 flex gap-2.5;
}
.recruitment-detail .attributes .item ul.describe li::before {
  content: "";
  flex: 0 0 0.3125rem;
  max-width: 0.3125rem;
  height: 0.3125rem;
  @apply inline-block rounded-full bg-body-text-99 mt-2;
}
.recruitment-detail .attributes .item ul li {
  @apply flex gap-3;
}
.recruitment-detail .attributes .item ul li + li {
  @apply mt-2;
}
.recruitment-detail .attributes .item ul .desc {
  @apply text-lg text-body-text-33 leading-1.33333;
}
.recruitment-detail .attributes .item ul .desc > a {
  @apply underline text-[#008BFF];
}
.recruitment-detail .attributes .item ul .icon {
  flex: 0 0 1.25rem;
  max-width: 1.25rem;
  width: 100%;
  height: 1.25rem;
}
.recruitment-detail .attributes .item ul .icon em {
  @apply text-2xl leading-none text-blue-500;
}
.recruitment-detail .buttom-wrapper {
  @apply p-8 bg-blue-500 flex flex-col gap-2.5 rounded-1 mb-8;
}
.recruitment-detail .buttom-wrapper a {
  @apply flex-center py-2 rounded-[calc(8/1920*100rem)];
}
.recruitment-detail .buttom-wrapper a:first-child {
  @apply bg-white;
}
.recruitment-detail .buttom-wrapper a:first-child span {
  @apply text-red-500;
}
.recruitment-detail .buttom-wrapper a:last-child {
  @apply bg-transparent border border-white;
}
.recruitment-detail .buttom-wrapper a:last-child span {
  @apply text-white;
}
.recruitment-detail .buttom-wrapper a span {
  @apply global-text-20;
}
.recruitment-detail .recruitment-other h2 {
  @apply text-2xl leading-1.33333 text-red-500 font-semibold;
}
.recruitment-detail .recruitment-other .item {
  @apply p-6 border border-[#E1E1E1] mt-3 rounded-1;
}
.recruitment-detail .recruitment-other .item h3 {
  @apply text-black global-text-20 font-bold mb-3 transition-all hover:text-red-500;
}
.recruitment-detail .recruitment-other .item p {
  @apply text-lg leading-1.33333;
}
.recruitment-detail .recruitment-other .item .location {
  @apply text-body-text-66 mb-2;
}
.recruitment-detail .recruitment-other .item .end-day {
  @apply text-red-500;
}

.apply-frm.row {
  @apply m-0;
}
.apply-frm h4 {
  @apply global-text-36 text-blue-500 mb-4.5;
}
.apply-frm [id*=_jobApply_upContact] {
  @apply w-full;
}
.apply-frm .col-left {
  @apply flex flex-wrap gap-4;
}
.apply-frm .col-left .form-group {
  flex: 0 0 100%;
  max-width: 100%;
}
@screen md {
  .apply-frm .col-left .form-group {
    flex: 0 0 calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}
.apply-frm .form-group {
  @apply w-full;
}
.apply-frm .form-group input:not([type=submit]), .apply-frm .form-group textarea {
  @apply w-full text-lg leading-1.33333 text-body-text-66  border border-l-blue-100 px-5 py-3 rounded-1 resize-none;
}
.apply-frm .form-group input:not([type=submit])::placeholder, .apply-frm .form-group textarea::placeholder {
  @apply w-full text-lg leading-1.33333 text-body-text-66;
}
.apply-frm .form-group input[type=submit] {
  @apply text-0 absolute inset-0 cursor-pointer;
}
.apply-frm .form-group textarea {
  min-height: 6.25rem;
}
.apply-frm .form-group[class*=attachfile] label {
  @apply text-base leading-1.5 text-blue-500 font-bold;
}
.apply-frm .form-group[class*=attachfile] span {
  @apply text-lg leading-1.33333 text-red-500;
}
.apply-frm .form-group[class*=attachfile] .ruFileWrap {
  @apply h-11 w-[calc(140/1920*100rem)];
}
.apply-frm .form-group[class*=attachfile] .ruInputs {
  @apply flex flex-col-reverse;
}
.apply-frm .form-group[class*=attachfile] .ruInputs li:last-child .ruFileWrap::before {
  content: "Upload File";
  @apply absolute top-0 left-0 flex-center text-black text-xl leading-1.4;
  @apply h-11 w-[calc(140/1920*100rem)] z-1 border-none rounded-[calc(8/1920*100rem)];
}
.apply-frm .form-group[class*=attachfile] .ruFileInput, .apply-frm .form-group[class*=attachfile] .ruBrowse {
  @apply h-full w-full left-0 p-0 border-none rounded-[calc(8/1920*100rem)] text-0 bg-stroke-CC;
}
.apply-frm .form-group[class*=attachfile] .ruRemove {
  @apply absolute top-0 left-0 p-0;
}
.apply-frm .form-group[class*=attachfile] [id*=_uplAttachFile1row], .apply-frm .form-group[class*=attachfile] [id*=_uplAttachFile2row] {
  @apply relative;
}
.apply-frm .col-right {
  @apply flex flex-wrap gap-4 mt-4;
}
.apply-frm .col-right .form-group {
  flex: 0 0 100%;
  max-width: 100%;
}
.apply-frm .col-right .form-group[class*=attachfile] {
  flex: 0 0 100%;
  max-width: 100%;
}
@screen md {
  .apply-frm .col-right .form-group[class*=attachfile] {
    flex: 0 0 calc(50% - 8px);
    max-width: calc(50% - 8px);
  }
}
.apply-frm .col-right .form-group[class*=attachfile] label {
  @apply text-base leading-1.5 text-blue-500 font-bold;
}
.apply-frm .col-right .form-group[class*=attachfile] label .required {
  @apply font-normal text-red-500 leading-1.33333 text-lg;
}
.apply-frm .frm-btn {
  @apply bg-red-500 inline-flex items-center gap-3 px-15 h-11 rounded-[calc(8/1920*100rem)] mt-8 relative;
}
.apply-frm .frm-btn::before {
  content: "Send";
  @apply text-xl font-normal text-white leading-[28/20];
}
.apply-frm .frm-btn::after {
  content: "\f178";
  @apply font-awesome text-xl leading-none text-white font-light;
}

.recruitment-detail-page .fancybox__content {
  max-width: 56.6666666667rem !important;
  @apply w-full py-10 px-20 !important;
}
.recruitment-detail-page .fancybox__content .is-close {
  @apply absolute right-0 !important;
}

.recruitment-our .desc {
  @apply relative lg:pl-8 global-text-24 text-black;
}
@screen lg {
  .recruitment-our .desc::before {
    content: "";
    @apply absolute top-0 left-0 h-full w-[calc(1/1920*100rem)] bg-blue-200;
  }
}

.recruitment-welfare .desc {
  @apply global-text-24 text-black xl:max-w-[calc(1184/1920*100rem)] mx-auto text-center mb-8;
}
.recruitment-welfare .item {
  @apply bg-[#F1F6FD] flex flex-col items-center text-center px-5 py-8 h-full;
  @apply min-h-[calc(264/1920*100rem)];
}
.recruitment-welfare .box-content {
  @apply mt-3;
}
.recruitment-welfare .box-content h3 {
  @apply global-text-20 font-semibold text-black;
}
.recruitment-welfare .box-content .desc {
  @apply mt-2 global-text-20 leading-1.33333 text-black;
}
.recruitment-welfare .box-img {
  @apply h-[calc(80/1920*100rem)] w-[calc(80/1920*100rem)] rounded-full bg-blue-500;
  @apply inline-flex items-center justify-center;
}
.recruitment-welfare .img-src {
  @apply h-[calc(44/1920*100rem)];
}
.recruitment-welfare .img-src img {
  @apply h-full object-contain;
}

.recruitment-list .table-wrapper table {
  @apply w-full;
}
.recruitment-list .table-wrapper thead tr {
  @apply bg-blue-500;
}
.recruitment-list .table-wrapper thead td {
  @apply text-white global-text-20 font-medium py-3.5;
}
.recruitment-list .table-wrapper thead td:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}
.recruitment-list .table-wrapper tbody tr:first-child {
  @apply bg-blue-100;
}
.recruitment-list .table-wrapper tbody tr:not(first-child) {
  border-left: 1px solid #E3EDFB;
}
.recruitment-list .table-wrapper tbody tr:not(first-child) td {
  border-right: 1px solid #E3EDFB;
  border-bottom: 1px solid #E3EDFB;
}
.recruitment-list .table-wrapper tbody td {
  @apply text-lg leading-1.33333 text-black py-3 text-center;
}
.recruitment-list .table-wrapper tbody td:nth-child(2) {
  @apply pl-5 text-left;
}
.recruitment-list .table-wrapper tbody td:nth-child(2) a {
  @apply transition-all;
}
.recruitment-list .table-wrapper tbody td:nth-child(2) a:hover {
  @apply text-red-500;
}
.recruitment-list .table-wrapper tbody td:last-child a {
  @apply text-blue-500;
}
@media (max-width: 575.98px) {
  .recruitment-list .table-wrapper {
    @apply overflow-auto;
  }
  .recruitment-list .table-wrapper table {
    @apply w-[calc(780/1920*100rem)];
  }
}
.recruitment-list .btn-1 {
  @apply min-w-fit;
}

.sustainability-1 .desc {
  @apply text-black global-text-24 my-8;
}
.sustainability-1 .desc p + p {
  @apply mt-2;
}
.sustainability-1 .img-src img {
  @apply rounded-1;
}

.sustainability-3 .desc {
  @apply text-center my-6 global-text-24 text-black;
}
.sustainability-3-swiper .img-src {
  height: 7.1354166667rem;
  text-align: center;
}
.sustainability-3-swiper .img-src img {
  @apply h-full object-contain;
}
@media (max-width: 1279.98px) {
  .sustainability-3-swiper {
    @apply pb-5;
  }
}

.sustainability-2 .box-img, .sustainability-2 .box-content {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.sustainability-2 .box-img img {
  @apply rounded-1;
}
.sustainability-2 .box-content {
  @apply rounded-1 flex items-center bg-blue-600;
}
.sustainability-2 .box-content h3 {
  @apply global-text-36 font-medium;
}
.sustainability-2 .box-content .desc {
  @apply global-text-20 mt-6;
}
@screen lg {
  .sustainability-2 .box-content .desc {
    max-height: 8.75rem;
    @apply overflow-y-auto pr-2;
  }
  .sustainability-2 .box-content .desc::-webkit-scrollbar {
    border-radius: 0.4166666667rem;
    width: 0.4166666667rem;
    @apply bg-white;
  }
  .sustainability-2 .box-content .desc::-webkit-scrollbar-thumb {
    border-radius: 0.4166666667rem;
    outline: 1px solid white;
    @apply bg-blue-500;
  }
}
.sustainability-2 .desc {
  @apply global-text-24 my-8;
}
.sustainability-2-swiper .img-src img {
  @apply rounded-1;
}
@media (max-width: 1279.98px) {
  .sustainability-2-swiper {
    @apply pb-5;
  }
}
@screen lg {
  .sustainability-2 .box-img, .sustainability-2 .box-content {
    flex: 0 0 calc(50% - 0.5208333333rem);
    max-width: calc(50% - 0.5208333333rem);
  }
}

@screen xl {
  .sustainability-4.section-large {
    @apply pt-[calc(114/1920*100rem)] py-[calc(180/1920*100rem)];
  }
}
.sustainability-4 .desc {
  @apply global-text-24 text-black mt-6;
}
@media (max-width: 1023.98px) {
  .sustainability-4 {
    background-image: none !important;
    @apply bg-background-F5;
  }
}

@screen xl {
  .sustainability-5.section-medium {
    @apply pt-8;
  }
}
.sustainability-5 ul {
  @apply flex items-center sm:justify-center gap-5 mb-5;
}
.sustainability-5 ul li.active a {
  @apply bg-red-500 text-white border-red-500;
}
.sustainability-5 ul a {
  @apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20;
  @apply inline-flex items-center justify-center py-2 px-2.5 whitespace-nowrap;
  @apply transition-all duration-300;
}
.sustainability-5 ul a:hover {
  @apply bg-red-500 text-white border-red-500;
}
@media (max-width: 575.98px) {
  .sustainability-5 ul {
    @apply overflow-x-scroll;
  }
}
.sustainability-5-swiper .swiper-slide {
  @apply h-auto;
}
.sustainability-5-swiper .item {
  @apply h-full bg-[#F1F6FD];
}
.sustainability-5-swiper .box-img img {
  border-radius: 0.2083333333rem 0.2083333333rem 0px 0px;
}
.sustainability-5-swiper .box-content {
  border-radius: 0px 0px 0.2083333333rem 0.2083333333rem;
  @apply p-6;
}
.sustainability-5-swiper h3 {
  @apply text-blue-500 global-text-24 font-medium mb-3 transition-all hover:text-red-500;
}
.sustainability-5-swiper .desc {
  @apply text-black text-lg leading-1.33333;
}
@media (max-width: 1279.98px) {
  .sustainability-5-swiper {
    @apply pb-5;
  }
}

.wwd-detail .aquaculture .desc {
  @apply global-text-24 text-black;
}
.wwd-detail .aquaculture .item {
  @apply text-center;
}
.wwd-detail .aquaculture .item h4 {
  @apply global-text-40 text-red-500 font-bold;
}
.wwd-detail .aquaculture .item .desc {
  @apply global-text-20 font-medium text-black;
}
.wwd-detail .certifications {
  @apply py-12;
}
.wwd-detail .certifications .item {
  @apply text-center;
}
.wwd-detail .certifications .desc {
  @apply global-text-24 text-black font-medium mt-6;
}
@media (max-width: 767.98px) {
  .wwd-detail .certifications {
    @apply pb-10;
  }
  .wwd-detail .certifications .swiper-pagination {
    @apply bottom-2.5;
  }
}
.wwd-detail .info .item + .item {
  @apply mt-5;
}
.wwd-detail .info .item:nth-child(odd) .box-content {
  @apply bg-[#F1F6FD];
}
.wwd-detail .info .item:nth-child(odd) .box-content h3 {
  @apply text-blue-500;
}
.wwd-detail .info .item:nth-child(odd) .box-content .desc {
  @apply text-black;
}
.wwd-detail .info .item:nth-child(even) .box-content {
  @apply bg-blue-500 text-white;
}
@screen lg {
  .wwd-detail .info .item:nth-child(odd) .box-img {
    @apply order-2;
  }
  .wwd-detail .info .item:nth-child(odd) .box-content {
    @apply order-1;
  }
  .wwd-detail .info .item:nth-child(even) .box-img {
    @apply order-1;
  }
  .wwd-detail .info .item:nth-child(even) .box-content {
    @apply order-2;
  }
}
.wwd-detail .info .box-content, .wwd-detail .info .box-img {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.wwd-detail .info .box-content img, .wwd-detail .info .box-img img {
  @apply rounded-1;
}
.wwd-detail .info .box-content {
  @apply rounded-1 flex items-center;
}
.wwd-detail .info .box-content h3 {
  @apply global-text-36 font-medium uppercase;
}
.wwd-detail .info .box-content .desc {
  @apply global-text-20 mt-6;
  @screen lg;
  max-height: 8.75rem;
  @apply overflow-y-auto pr-2;
}
.wwd-detail .info .box-content .desc::-webkit-scrollbar {
  border-radius: 0.4166666667rem;
  width: 0.4166666667rem;
  @apply bg-white;
}
.wwd-detail .info .box-content .desc::-webkit-scrollbar-thumb {
  border-radius: 0.4166666667rem;
  outline: 1px solid white;
  @apply bg-blue-500;
}
@screen lg {
  .wwd-detail .info .box-img {
    flex: 0 0 calc(50% - 0.5208333333rem);
    max-width: calc(50% - 0.5208333333rem);
  }
  .wwd-detail .info .box-content {
    flex: 0 0 calc(50% - 0.5208333333rem);
    max-width: calc(50% - 0.5208333333rem);
  }
}

.wwd-list .item + .item {
  @apply mt-5;
}
@screen lg {
  .wwd-list .item:nth-child(odd) .box-img {
    @apply order-1;
  }
  .wwd-list .item:nth-child(odd) .box-content {
    @apply order-1;
  }
  .wwd-list .item:nth-child(even) .box-img {
    @apply order-2;
  }
  .wwd-list .item:nth-child(even) .box-content {
    @apply order-1;
  }
}
.wwd-list .box-img, .wwd-list .box-content {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.wwd-list .box-content {
  @apply bg-[#F1F6FD];
}
.wwd-list .box-content .icon {
  @apply h-16 w-16;
}
.wwd-list .box-content .icon img {
  @apply h-full w-full object-contain;
}
@screen lg {
  .wwd-list .box-img {
    flex: 0 0 65.8536585366%;
    max-width: 65.8536585366%;
  }
  .wwd-list .box-content {
    flex: 0 0 32.6219512195%;
    max-width: 32.6219512195%;
  }
}