.news-1
	background: #f7fafe
	display: flex
	&::before
		content: ''
		background-image: url('../img/news/line.svg')
		background-size: cover
		background-repeat: no-repeat
		background-position: center
		@apply absolute left-0 -bottom-5 w-full h-40
	.heading
		font-family: League Spartan
		font-size: 48px
		font-style: normal
		font-weight: 500
		line-height: calc( 56px / 48px )
		@apply uppercase
	&-swiper
		.swiper-container
			position: relative
			width: 70%
			margin: 0 auto
		.swiper
			&-button-next, &-button-prev
				color: #08518F
				border: 1px solid #9CB9D2
				border-radius: 50%
				width: 60px
				height: 60px
		@media(max-width: 1279.98px)
			@apply pb-5

.news-item
	&:hover
		h4
			@apply text-red-500
	.news-slide-date
		@apply flex my-5 text-body-text-99 gap-2 text-xl
		p
			@apply leading-1.25
	h4
		@apply text-28 leading-1.25 text-black font-medium line-clamp-3
		@apply transition-all duration-300