.contact-page
	.contact-left
		@apply lg:pr-20
		h1
			font-size: rr(56px)
			@apply font-secondary font-normal text-red-500 leading-1.25 relative pb-6 mb-5
			span
				@apply text-blue-500 global-text-48 font-thin block font-primary
			&::after
				content: ''
				@apply h-[calc(1/1920*100rem)] w-full absolute bottom-0 left-0 bg-stroke-CC
		.item
			@apply py-5 relative
			&::after
				content: ''
				@apply h-[calc(1/1920*100rem)] w-full absolute bottom-0 left-0 bg-stroke-CC
			li
				@apply flex gap-5 md:gap-3
				&+li
					@apply mt-2
		.desc
			@apply text-lg text-body-text-33 leading-1.33333
			&>a
				@apply underline text-[#008BFF]
		.icon
			flex: 0 0 calc(24/560*100%)
			max-width: calc(24/560*100%)
			width: 100%
			height: rr(24px)
			em
				@apply text-2xl leading-none text-blue-500
		.location-company
			@apply global-text-24 font-medium mb-4
	.contact-right
		@apply bg-[#F1F6FD] px-8 py-11 lg:-mr-8
	.form-title
		@apply text-center global-text-20
		span
			@apply text-blue-500
	.form-contact
		.form-group
			@apply mt-3
		.frm-btnwrap
			@apply flex justify-center
		input:not([type="submit"]), textarea
			@apply w-full resize-none rounded-1
			&::placeholder
				@apply text-body-text-66 text-lg leading-1.33333
		input
			&:not([type="submit"])
				@apply px-5 py-3 text-body-text-66 text-lg leading-1.33333
			&[type="submit"]
				@apply text-0 absolute cursor-pointer inset-0 #{!important}
		textarea
			@apply h-[calc(112/1920*100rem)] pt-3 pl-5
		.frm-btn
			@apply bg-red-500 inline-flex items-center gap-3 px-15 h-11 rounded-[calc(8/1920*100rem)] relative
			&::before
				content: 'Send'
				@apply text-xl font-normal text-white leading-[28/20]
			&::after
				content: '\f178'
				@apply font-awesome text-xl leading-none text-white font-light