.wwd-detail
	.aquaculture
		.desc
			@apply global-text-24 text-black
		.item
			@apply text-center
			h4
				@apply global-text-40 text-red-500 font-bold
			.desc
				@apply global-text-20 font-medium text-black
	.certifications
		@apply py-12
		.item
			@apply text-center
		.desc
			@apply global-text-24 text-black font-medium mt-6
		@media(max-width: 767.98px)
			@apply pb-10
			.swiper-pagination
				@apply bottom-2.5
	.info
		.item
			&+.item
				@apply mt-5
			&:nth-child(odd)
				.box-content
					@apply bg-[#F1F6FD]
					h3
						@apply text-blue-500
					.desc
						@apply text-black
			&:nth-child(even)
				.box-content
					@apply bg-blue-500 text-white
			@screen lg
				&:nth-child(odd)
					.box-img
						@apply order-2
					.box-content
						@apply order-1
				&:nth-child(even)
					.box-img
						@apply order-1
					.box-content
						@apply order-2
		.box-content, .box-img
			flex: 0 0 100%
			max-width: 100%
			width: 100%
			img
				@apply rounded-1
		.box-content
			@apply rounded-1 flex items-center
			h3
				@apply global-text-36 font-medium uppercase
			.desc
				@apply global-text-20 mt-6
				@screen lg
				max-height: rr(168px)
				@apply overflow-y-auto pr-2
				&::-webkit-scrollbar
					border-radius: rr(8px)
					width: rr(8px)
					@apply bg-white
				&::-webkit-scrollbar-thumb
					border-radius: rr(8px)
					outline: 1px solid white
					@apply bg-blue-500
		@screen lg
			.box-img
				flex: 0 0 calc(50% - rr(10px))
				max-width: calc(50% - rr(10px))
			.box-content
				flex: 0 0 calc(50% - rr(10px))
				max-width: calc(50% - rr(10px))