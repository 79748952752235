.home-banner
	.swiper-slide
		.video-wrapper
			@apply h-screen
			video
				@apply w-full h-full object-cover
	.play-icon
		@apply text-white w-20 h-20 text-xl rounded-full flex-center bg-blue-500
		&.is-playing
			@apply hidden
	&::before
		content: ''
		background-image: url("../img/icon/before_homebanner.svg")
		background-position: center bottom
		background-repeat: no-repeat
		background-size: cover
		@apply absolute bottom-0 left-0 w-full z-20 h-[38px] translate-y-5
		@screen md
			@apply h-[calc(170/1920*100rem)] translate-y-[calc(128/1920*100rem)] z-10
	.item
		@apply relative z-1
		&::before, &::after
			content: ''
			@apply w-full z-10 absolute left-0
		&::before
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)
			@apply top-0 h-[calc(170/1920*100rem)]
		&::after
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)
			@apply bottom-0 h-[calc(290/1920*100rem)]
	.box-content
		@apply absolute bottom-[20%] left-1/2 -translate-x-1/2 z-20 w-full text-center
		@apply md:bottom-[15%] w-full xl:bottom-[calc(160/1920*100rem)]
		p
			@apply text-white global-text-24 md:global-text-40 xl:global-text-48
			span
				@apply inline-block mx-3 xl:mx-5 font-bold global-text-24 md:global-text-40 xl:text-64 xl:leading-[calc(64/56)]
	.swiper-pagination
		@apply pointer-events-none
		.swiper-pagination-bullet
			@apply pointer-events-auto
	@media(max-width: 1279.98px)
		.container
			@apply max-w-full px-3.75 #{!important}
