.pagination
	@apply flex-center gap-3 mt-8
	li
		&.active
			a, span
				@apply bg-white text-red-500 border-red-500
	a, span
		@apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20
		@apply inline-flex justify-center items-center w-11 h-11
		@apply transition-all duration-300
		&:hover
			@apply bg-red-500 text-white border-red-500
		&.FirstPage, &.BackPage, &.NextPage, &.LastPage
			@apply hidden
