.news-2
	ul
		@apply flex items-center justify-center gap-5 mb-5
		li
			&.active
				a
					@apply bg-red-500 text-white border-red-500
		a
			@apply bg-transparent border-blue-200 border rounded-1 text-blue-500 global-text-20
			@apply inline-flex items-center justify-center py-2 px-2.5
			@apply transition-all duration-300
			&:hover
				@apply bg-red-500 text-white border-red-500

.showListNews
	&:hover
		h4
			@apply text-red-500
	.news-slide-date
		@apply flex my-3 text-body-text-99 gap-2
		p
			@apply leading-1.25
	h4
		@apply global-text-24 text-black font-medium line-clamp-3
		@apply transition-all duration-300
