.social-fixed
	li
		&:nth-child(1)
			a
				@apply bg-blue-500 h-[calc(48/1920*100rem)]
		&:nth-child(3), &:nth-child(2)
			a
				@apply p-3 h-[calc(72/1920*100rem)] border-stroke-CC border
			img
				@apply h-[calc(48/1920*100rem)] object-contain
	a
		@apply flex-center flex-col w-[calc(72/1920*100rem)] text-white rounded-[calc(8/1920*100rem)] py-2 bg-white
		em
			@apply text-2xl
		span
			@apply text-[calc(12/1920*100rem)] leading-1.5 mt-1

.social-fixed-wrapper
	.ActionMenuBody
		left: unset !important
		@apply right-0
