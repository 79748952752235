.recruitment-detail
	background-position: 223px 0
	.info
		@apply flex flex-wrap lg:flex-nowrap gap-y-5 justify-between p-8 bg-[#F1F6FD] rounded-1
		.box-img, .box-content
			flex: 0 0 100%
			max-width: 100%
			width: 100%
		.box-img
			img
				@apply rounded-1
		.box-content
			table
				@apply w-full
			td
				@apply global-text-20 text-black py-2.5
				&:first-child
					@apply font-semibold
			tr
				@apply border-t border-[#e1e1e1]
				&:last-child
					@apply border-b border-[#e1e1e1]
					td
						@apply text-red-500
		@screen lg
			.box-img
				flex: 0 0 calc(387/912*100%)
				max-width: calc(387/912*100%)
			.box-content
				flex: 0 0 calc(494/912*100%)
				max-width: calc(494/912*100%)

	.attributes
		.item
			@apply mt-8 p-8 bg-[#F1F6FD]  rounded-1
			ul
				&.describe
					li
						@apply text-black global-text-20 flex gap-2.5
						&::before
							content: ''
							flex: 0 0 rr(6px)
							max-width: rr(6px)
							height: rr(6px)
							@apply inline-block rounded-full bg-body-text-99 mt-2
			ul
				li
					@apply flex gap-3
					&+li
						@apply mt-2
				.desc
					@apply text-lg text-body-text-33 leading-1.33333
					&>a
						@apply underline text-[#008BFF]
				.icon
					flex: 0 0 rr(24px)
					max-width: rr(24px)
					width: 100%
					height: rr(24px)
					em
						@apply text-2xl leading-none text-blue-500
	.buttom-wrapper
		@apply p-8 bg-blue-500 flex flex-col gap-2.5 rounded-1 mb-8
		a
			@apply flex-center py-2 rounded-[calc(8/1920*100rem)]
			&:first-child
				@apply bg-white
				span
					@apply text-red-500
			&:last-child
				@apply bg-transparent border border-white
				span
					@apply text-white
			span
				@apply global-text-20
	.recruitment-other
		h2
			@apply text-2xl leading-1.33333 text-red-500 font-semibold
		.item
			@apply p-6 border border-[#E1E1E1] mt-3 rounded-1
			h3
				@apply text-black global-text-20 font-bold mb-3 transition-all hover:text-red-500
			p
				@apply text-lg leading-1.33333
			.location
				@apply text-body-text-66 mb-2
			.end-day
				@apply text-red-500

.apply-frm
	&.row
		@apply m-0

	h4
		@apply global-text-36 text-blue-500 mb-4.5
	[id*='_jobApply_upContact']
		@apply w-full
	.col-left
		@apply flex flex-wrap gap-4
		.form-group
			flex: 0 0 100%
			max-width: 100%
			@screen md
				flex: 0 0 calc( 50% - 8px )
				max-width: calc( 50% - 8px )
	.form-group
		@apply w-full
		input:not([type="submit"]),textarea
			@apply w-full text-lg leading-1.33333 text-body-text-66  border border-l-blue-100 px-5 py-3 rounded-1 resize-none
			&::placeholder
				@apply w-full text-lg leading-1.33333 text-body-text-66
		input[type="submit"]
			@apply text-0 absolute inset-0 cursor-pointer
		textarea
			min-height: rr(120px)
		&[class*="attachfile"]
			label
				@apply text-base leading-1.5 text-blue-500 font-bold
			span
				@apply text-lg leading-1.33333 text-red-500
			.ruFileWrap
				@apply h-11 w-[calc(140/1920*100rem)]
			.ruInputs
				@apply flex flex-col-reverse
				li:last-child
					.ruFileWrap
						&::before
							content: 'Upload File'
							@apply absolute top-0 left-0 flex-center text-black text-xl leading-1.4
							@apply h-11 w-[calc(140/1920*100rem)] z-1 border-none rounded-[calc(8/1920*100rem)]
			.ruFileInput, .ruBrowse
				@apply h-full w-full left-0 p-0 border-none rounded-[calc(8/1920*100rem)] text-0 bg-stroke-CC
			.ruRemove
				@apply absolute top-0 left-0 p-0
			[id*="_uplAttachFile1row"], [id*="_uplAttachFile2row"]
				@apply relative
	.col-right
		@apply flex flex-wrap gap-4 mt-4
		.form-group
			flex: 0 0 100%
			max-width: 100%
			&[class*="attachfile"]
				flex: 0 0 100%
				max-width: 100%
				@screen md
					flex: 0 0 calc( 50% - 8px )
					max-width: calc( 50% - 8px )
				label
					@apply text-base leading-1.5 text-blue-500 font-bold
					.required
						@apply font-normal text-red-500 leading-1.33333 text-lg
	.frm-btn
		@apply bg-red-500 inline-flex items-center gap-3 px-15 h-11 rounded-[calc(8/1920*100rem)] mt-8 relative
		&::before
			content: 'Send'
			@apply text-xl font-normal text-white leading-[28/20]
		&::after
			content: '\f178'
			@apply font-awesome text-xl leading-none text-white font-light

.recruitment-detail-page
	.fancybox__content
		max-width: rr(1088px) !important
		@apply w-full py-10 px-20 #{!important}
		.is-close
			@apply absolute right-0 #{!important}