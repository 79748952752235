.btn
	@apply inline-flex items-center justify-center
	&.btn-1
		@apply gap-3 px-6 py-2 rounded-[calc(8/1920*100rem)] bg-blue-100 global-text-20 text-blue-500 min-w-[calc(195/1920*100rem)]
		@apply transition-all duration-300
		&:hover
			@apply text-white bg-red-500
	&.btn-2
		@apply gap-3 px-6 py-2 rounded-[calc(8/1920*100rem)] bg-red-500 global-text-20 text-white min-w-[calc(171/1920*100rem)]
	&.btn-3
		@apply gap-3 px-7.5 py-2 rounded-[calc(8/1920*100rem)] bg-white global-text-20 text-blue-500 min-w-[calc(304/1920*100rem)]
		@apply transition-all duration-300
		&:hover
			@apply text-white bg-red-500