.home-6
	&.section-large
		@screen xl
			@apply pb-[calc(200/1920*100rem)]
	&::before
		content: ''
		background-image: url(../img/home/6.png)
		background-size: cover
		background-repeat: no-repeat
		background-position: center
		height: rr(160px)
		@apply absolute -top-5 left-0 w-full
	.desc
		@apply mt-6 global-text-20 text-white max-w-[calc(1056/1920*100rem)] mx-auto text-center