.product-detail
	&.section-medium
		@apply pb-0
	.box-left
		@apply flex justify-between flex-wrap lg:flex-nowrap
	.desc
		@apply global-text-20 text-black
	.swiper-thumb, .swiper-main
		flex: 0 0 100%
		max-width: 100%
		width: 100%
	.swiper-thumb		
		@apply order-2 mt-5 lg:mt-0
		.btn-prev
			@apply absolute top-1/2 left-0
			&::before
				content: '\f077'
				@apply text-blue-500 font-light text-2xl md:text-40 leading-none
		.btn-next
			@apply absolute top-1/2 right-0
			&::before
				content: '\f078'
				@apply text-blue-500 font-light text-2xl md:text-40 leading-none
		@media(max-width: 1023.98px)
			@apply px-14
			.btn-prev, .btn-next
				@apply -translate-y-1/2
				&::before
					@apply -rotate-90
		@media(max-width: 767.98px)
			@apply px-7.5
	@screen lg
		.swiper-thumb
			flex: 0 0 calc(102/864*100%)
			max-width: calc(102/864*100%)
			@apply order-1 py-14
			.swiper
				height: rr(406px)
			.btn-prev
				@apply top-0 left-1/2 -translate-x-1/2
				
			.btn-next
				top: unset
				@apply bottom-0 left-1/2 -translate-x-1/2
				
		.swiper-main
			flex: 0 0 calc(752/864*100%)
			max-width: calc(752/864*100%)
			@apply order-2

.product-info 
	.tabslet-tab
		@apply flex gap-5
		li
			&.active
				a
					@apply bg-red-500 border-red-500 text-white
		a
			@apply inline-flex items-center justify-center px-2.5 py-2 global-text-20 text-blue-500
			@apply bg-transparent border border-blue-200 rounded-1
			@apply transition-all duration-300
			&:hover
				@apply bg-red-500 border-red-500 text-white
	.tabslet-content
		@apply mt-5
		h3
			@apply text-blue-500 font-semibold global-text-24 mb-5
		p
			@apply global-text-20
			&+p
				@apply mt-2
.productpage
	.product-form
		@apply hidden

.product-form
	.form-wrap
		input:not([type="submit"]), textarea
			@apply w-full resize-none rounded-1
			&::placeholder
				@apply text-body-text-66 text-lg leading-1.33333
		input
			&:not([type="submit"])
				@apply px-5 py-3 text-body-text-66 text-lg leading-1.33333
			&[type="submit"]
				@apply text-0 absolute cursor-pointer inset-0 #{!important}
		textarea
			@apply mt-5 h-[calc(112/1920*100rem)] pt-3 pl-5
		.frm-btn
			@apply bg-red-500 mt-5 inline-flex items-center gap-3 px-15 h-11 rounded-[calc(8/1920*100rem)]
			&::before
				content: 'Send'
				@apply text-xl font-normal text-white leading-[28/20]
			&::after
				content: '\f178'
				@apply font-awesome text-xl leading-none text-white font-light

.product-other-swiper
	@media(max-width: 1279.98px)
		@apply pb-5